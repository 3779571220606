<br/>
<div style="text-align: center;">
  <h1 style="font-weight: bold; color:#1c2453;">{{"users" | translate}}</h1>
</div>

<div>
  <mat-form-field appearance="standard">
    <mat-label>{{"common.search" | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
  </mat-form-field>
  <div style="float: right; display: inline;">
    <button (click)="onClickAdd()"  mat-raised-button>{{ "newUser" | translate}}</button>
  </div> 
  <table mat-table matTableResponsive [dataSource]="dataSource" matSort class="mat-elevation-z16">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"id" | translate}}  </th>
      <td mat-cell *matCellDef="let user"> {{user.id}} </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"firstName" | translate}}  </th>
      <td mat-cell *matCellDef="let user"> {{user.firstName}} </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"lastName" | translate}}  </th>
      <td mat-cell *matCellDef="let user">{{user.lastName}}</td>
    </ng-container>
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"username" | translate}}  </th>
      <td mat-cell *matCellDef="let user"> {{user.username}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"email" | translate}}  </th>
        <td mat-cell *matCellDef="let user"> {{user.email}}</td>
    </ng-container>
    <ng-container matColumnDef="enable/disable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.enable' | translate }}"
        matTooltipPosition="above" (click)="onClickUpdateStatus(user.username, 'ACTIVE')" *ngIf="!user.enabled">
          <mat-icon [ngStyle]="{'color':'green'}">check_circle</mat-icon>
        </button>
        <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.disable' | translate }}"
        matTooltipPosition="above" (click)="onClickUpdateStatus(user.username, 'INACTIVE')"  *ngIf="user.enabled">
          <mat-icon [ngStyle]="{'color':'red'}">block</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.edit' | translate }}"
        matTooltipPosition="above" (click)="onClickEdit(user.username)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.delete' | translate }}"
        matTooltipPosition="above" (click)="onClickDelete(user.username)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
</div>
 
