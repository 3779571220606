import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { API } from 'src/app/global/api.endpoints';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, Open_Hours_Gb, Open_Hours_Hun } from 'src/app/global/global.constant';
import { OpenHoursService } from 'src/app/service/open-hours.service';
import { ShopTranslatableService } from 'src/app/service/shop-translatable.service';
import { ShopService } from 'src/app/service/shop.service';
import { OpenHoursDialogComponent } from './open-hours-dialog/open-hours-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { TagService } from 'src/app/service/tag.service';
import { Tag } from 'src/app/models';
import { ShopTagService } from 'src/app/service/shop-tag.service';
import { HttpClient} from '@angular/common/http';
import { NotificationService } from 'src/app/service/notification.service';
import { MediaService } from 'src/app/service/media.service';
import { ArticleStatus, ShopType } from 'src/app/global/global.enums';
import { ConfigInitService } from 'src/app/init/config-init.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  public shopForm: FormGroup;
  public shopHuForm: FormGroup;
  public shopGbForm: FormGroup;
  public langForm: FormGroup;

  public hunFormVisible: boolean = true;
  public gbFormVisible: boolean = false;

  public shopTransaltableHu: any;
  public shopTransaltableGb: any;

  public shopId: number;

  public openHours: any[] = [];

  public openHoursCreateable: any[] = []

  public openHoursUpdateable: any = [];

  public tags: any[]=[];
  public existingTags: any[]=[];
  public removableTags: any[]=[];
  public newTags: any[]=[];
  public selectable: boolean= true;
  public visible: boolean= true;
  public removable: boolean= true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public uploadedLogoImage: File;
  public uploadedLogoImageName: string ="";

  public uploadedGooglePlayImage: File;
  public uploadedGooglePlayImageName: string ="";

  public uploadedAppleStoreImage: File;
  public uploadedAppleStoreImageName: string ="";

  public isGoogleAppleLink: boolean = false;

  public uploadedShopGalleryImages: File[] =[];
  public galleryImageNames: string[] = [];
  public shopImages: any[] =[];
  public removabelShopImages: any[] = [];

  public imageSizeLimit: number;

  public openHoursView: String = "";

  public isEditMode: boolean = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    width: '40rem',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    uploadUrl: API.uploadImage,
    toolbarHiddenButtons: [
      [   
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'link',
        'unlink',
        'heading',
        'indent',
        'outdent',
        'strikeThrough',
        'subscript',
        'superscript',
      ]
     ]
  };


  constructor(
    private shopService: ShopService,
    private shopTranslatableService: ShopTranslatableService,
    private openHoursService: OpenHoursService,
    private tagService: TagService,
    private shopTagService: ShopTagService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private mediaService: MediaService,
    private http:HttpClient,
    private configService: ConfigInitService
  ) {
      this.shopId = this.route.snapshot.params.id;
      this.shopForm = new FormGroup({
        email: new FormControl('',[Validators.email]),
        website: new FormControl(''),
        phone: new FormControl(''),
        shopStatus: new FormControl(ArticleStatus.DRAFT),
        shopType: new FormControl(ShopType.SHOP),
        logoId: new FormControl(''),
        googlePlayLinkImageId: new FormControl(''),
        appleStoreLinkImageId: new FormControl(''),
        googlePlayLink: new FormControl(''),
        appleStoreLink: new FormControl('')
      })
      this.shopHuForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        openHours: new FormControl(''),
        location: new FormControl(''),
        shopId: new FormControl( this.shopId != null ?  this.shopId : ''),
        lang: new FormControl('hu')
      })
      this.shopGbForm = new FormGroup({
        name: new FormControl(''),
        description: new FormControl(''),
        openHours: new FormControl(''),
        location: new FormControl(''),
        shopId: new FormControl( this.shopId ?  this.shopId : ''),
        lang: new FormControl('gb')
      })
      this.langForm = new FormGroup({
        lang: new FormControl('', [Validators.required])
      })
   }

  ngOnInit(): void {
    this.getImageSizeLimit();
    this.getExistingTags();
    if(this.shopId != null){
      this.isEditMode = true;
      this.getOpenHours(this.shopId);
      this.getShopTranslatable(this.shopId, this.route.snapshot.params.shopTranslatableId);
      this.getTags(this.shopId); 
      this.getShopImages(this.shopId);
    }else{
      this.shopHuForm.patchValue({ openHours: Open_Hours_Hun })
      this.shopGbForm.patchValue({ openHours: Open_Hours_Gb })
      this.langForm.patchValue({ lang:'hu'})
    }

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if(this.shopId != null){
        this.getOpenHours(this.shopId);
      }
      if(this.openHoursCreateable != null){
        this.getOpenHoursView(this.openHoursCreateable);
      }
    });
  }

  private getImageSizeLimit(){
    this.configService.getConfig().subscribe(
      data => {
        this.imageSizeLimit = data['IMAGE_SIZE_LIMIT']
      }
    )
  }

  private getShopTranslatable(shopId: number, shopTranslatableId:number){
    this.shopTranslatableService.getShopTranslatable(shopTranslatableId).subscribe(
      (data: any) => {
        this.setShopFormGroup(data);
        if(data.lang === 'hu'){
          this.hunFormVisible =true;
          this.gbFormVisible =false;
          this.setHunFormGroup(data);
        }else{
          this.hunFormVisible =false;
          this.gbFormVisible =true;
          this.setGbFormGroup(data);
        }
        if(data.shop.logoId != null){
          this.getUploadedLogo(data.shop.logoId);
        }
        if(data.shop.googlePlayLinkImageId != null){
          this.getGooglePlayImage(data.shop.googlePlayLinkImageId);
        }
        if(data.shop.appleStoreLinkImageId != null){
          this.getAppleStoreImage(data.shop.appleStoreLinkImageId);
        }
      }
    )
    this.shopTranslatableService.getShopTranslatableByShopIdAndLang(shopId, 'gb').subscribe(
      (data: any) => {
        this.shopTransaltableGb = data;
        this.setGbFormGroup(data);
      }
    );
    this.shopTranslatableService.getShopTranslatableByShopIdAndLang(shopId, 'hu').subscribe(
      (data: any) => {
        this.shopTransaltableHu = data;
        this.setHunFormGroup(data);
      }
    ); 
  }


  private setShopFormGroup(shop: any){
    this.langForm.patchValue({ lang: shop.lang })
    this.shopForm.patchValue({ email: shop.shop.email })
    this.shopForm.patchValue({ phone: shop.shop.phone })
    this.shopForm.patchValue({ website: shop.shop.website })
    this.shopForm.patchValue({ shopStatus: shop.shop.shopStatus })
    this.shopForm.patchValue({ googlePlayLink: shop.shop.googlePlayLink})
    this.shopForm.patchValue({ appleStoreLink: shop.shop.appleStoreLink})
  }

  private setHunFormGroup(shop: any){
    this.shopHuForm.patchValue({ name: shop.name })
    this.shopHuForm.patchValue({ description: shop.description })
    this.shopHuForm.patchValue({ openHours: shop.openHours })
    this.shopHuForm.patchValue({ location: shop.location })
  }

  private setGbFormGroup(shop: any){
    if(shop != null){
      this.shopGbForm.patchValue({ name: shop.name })
      this.shopGbForm.patchValue({ description: shop.description })
      this.shopGbForm.patchValue({ openHours: shop.openHours })
      this.shopGbForm.patchValue({ location: shop.location })
    }
    
  }

  private getUploadedLogo(id: number){
    this.mediaService.getMedia(id).subscribe(
      (data: any)=> {
          this.uploadedLogoImageName = data.filePath.substring(15);
          this.shopForm.patchValue({logoId: data.id});  
      }
    )
  }

  private getGooglePlayImage(id: number){
    this.mediaService.getMedia(id).subscribe(
      (data: any)=> {
          this.uploadedGooglePlayImageName = data.filePath.substring(15);
          this.shopForm.patchValue({googlePlayLinkImageId: data.id});  
      }
    )
  }

  private getAppleStoreImage(id: number){
    this.mediaService.getMedia(id).subscribe(
      (data: any)=> {
          this.uploadedAppleStoreImageName = data.filePath.substring(15);
          this.shopForm.patchValue({appleStoreLinkImageId: data.id});  
      }
    )
  }

  private getShopImages(shopId: number){
    this.mediaService.getShopImages(shopId).subscribe(
      data => {
        this.shopImages = data as any[];
        for(let shopImage of data as any[]){
          this.galleryImageNames.push(shopImage.media.filePath.substring(15))
        }
      }
    );
        
  }

  private getOpenHours(id: number){
    this.openHoursService.getOpenHoursByShopId(id).subscribe(
      data => {
        this.openHours = data as any[]
        this.getOpenHoursView(data as any[]);
      }
    )
  }
  
  private getOpenHoursView(openHours: any[]){
    this.openHoursView = "";
    for(let o of openHours){
      this.openHoursView += 
      this.translateService.instant(o.whatWeekDay) + ": " 
      + (o.openingHour < 10 ? "0" + o.openingHour : o.openingHour) + ":" 
      + (o.openingMinute < 10 ? "0" + o.openingMinute : o.openingMinute)
      + " - " 
      + (o.closeHour < 10 ? "0" + o.closeHour : o.closeHour) + ":" 
      + (o.closeMinute < 10 ? "0" + o.closeMinute : o.closeMinute)  + "\n"
    }   
  }

  private getTags(shopId: number){
    this.shopTagService.getShopTagsByShopId(shopId).subscribe(
      data => {
        for(let shopTag of data as any[]){
          this.tags.push(shopTag.tag.title);
        }
      }
    )
  }

  private getExistingTags(){
    this.tagService.getTags().subscribe(
      data => {
        for(let tag of data as any[]){
          this.existingTags.push(tag.title)
        }
      }
    )
  }

  onClickSetOpenHours(){
    const dialogRef = this.matDialog.open(OpenHoursDialogComponent, {
      width: '570px',
      height: '570px',
      data: { shopId: this.shopId, openHoursExisting: this.openHours }
    });

    dialogRef.afterClosed().subscribe(
      (data: any) => {
          if(data != "Mégsem"){
          this.openHoursCreateable = data;
          this.getOpenHoursView(data);
         // this.openHours=this.openHoursCreateable;
          if(this.isEditMode){
            this.openHoursUpdateable =  data;
            this.getOpenHoursView(this.openHoursUpdateable);
            //this.openHours=this.openHoursUpdateable;
          }
        }else{
          this.getOpenHoursView(this.openHours);
        }
      }
    );
  }

  onClickSave(){
    if(this.isEditMode === true){
     this.updateShop();
    }else{
      this.saveShop();
    }
  }

  private saveShop(){
    this.shopForm.patchValue({ created: new Date() })
    this.shopService.createShop(this.shopForm.value).subscribe(
      (data: any) => {
        if(this.shopHuForm.controls.name.value != '' || this.shopHuForm.controls.description.value != ''){
          this.shopHuForm.patchValue({ shopId: data.id })
          this.shopTranslatableService.createShopTranslatable(this.shopHuForm.value).subscribe();
          this.saveOpenhours(data.id);
        }
        if(this.shopGbForm.controls.name.value != '' || this.shopGbForm.controls.description.value != ''){
          this.shopGbForm.patchValue({ shopId: data.id })
          this.shopTranslatableService.createShopTranslatable(this.shopGbForm.value).subscribe();
        }
        this.saveTags(data.id);
        if(this.uploadedLogoImage != null && this.uploadedLogoImageName !=""){
          this.saveShopLogoImage(data.id);
        }
        if(this.uploadedGooglePlayImage != null && this.uploadedGooglePlayImageName !=""){
          this.saveGoogleMedia(data.id, this.uploadedGooglePlayImage)
        }
        if(this.uploadedAppleStoreImage != null && this.uploadedAppleStoreImageName !=""){
          this.saveAppleMedia(data.id, this.uploadedAppleStoreImage)
        }
        if(this.uploadedShopGalleryImages != null && this.uploadedShopGalleryImages.length > 0){
          this.saveShopGalleryImages(data.id);
        }
       
      }
    ).add(
      () => this.router.navigate(['/shops'])
    );
  }

  private saveOpenhours(shopId: number){
    for(let openHour of this.openHoursCreateable){
      openHour.shopId = shopId;
      this.openHoursService.createOpenHours(openHour).subscribe();
    }
  }

  private saveTags(shopId: number){
    for(let t of this.tags){
      let tag: Tag ={
        title: ''
      }
      if(!this.existingTags.includes(t)){
        tag.title = t;
        this.tagService.createTag(tag).subscribe(
          (data: any) => {
            this.shopTagService.createShopTag(shopId, data.id).subscribe();
          }
        );
      }else{
        this.tagService.getTagByTitle(t).subscribe(
          (data: any) => {
            this.shopTagService.createShopTag(shopId, data.id).subscribe();
          }
        )
      }
    }
  }

  private saveShopLogoImage(shopId: number) {
    const imageFormData = new FormData();
    imageFormData.append('file', this.uploadedLogoImage, this.uploadedLogoImage.name);


    this.http.post(API.uploadShopLogoImage + shopId, imageFormData,{  reportProgress: true,
      observe: 'events'}).subscribe(
        (response:any)=> {
          if (response.status === 200) {
            this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
          }else{
            this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
          }

        }
      );
  }

  private saveGoogleMedia(id: number, media: File) {
    const imageFormData = new FormData();
    imageFormData.append('file', media, media.name);

    this.http.post(API.uploadGoogleMedia + id + "/" + "SHOP", imageFormData,{  reportProgress: true,
      observe: 'events'}).subscribe(
        (response:any)=> {
          if (response.status === 200) {
            this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
          }else{
            this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
          }

        }
      );
  }

  private saveAppleMedia(id: number, media: File) {
    const imageFormData = new FormData();
    imageFormData.append('file', media, media.name);

    this.http.post(API.uploadAppleMedia + id + "/" + "SHOP", imageFormData,{  reportProgress: true,
      observe: 'events'}).subscribe(
        (response:any)=> {
          if (response.status === 200) {
            this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
          }else{
            this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
          }

        }
      );
  }


  private saveShopGalleryImages(shopId: number){
    for(let image of this.uploadedShopGalleryImages){
      const imageFormData = new FormData();
      imageFormData.append('file', image, image.name);
      this.http.post(API.uploadShopImage +shopId, imageFormData,{  reportProgress: true,
        observe: 'events'}).subscribe(
          (response:any)=> {
            if (response.status === 200) {
              this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
            }else{
              this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
            }
          }
        )
    }

  }

  private updateShop(){
    this.shopService.updateShop(this.shopId, this.shopForm.value).subscribe(
      () => {
        if(this.shopHuForm.controls.name.value != '' || this.shopHuForm.controls.description.value != ''){
          this.shopHuForm.patchValue({ shopId: this.shopId});
          if(this.shopTransaltableHu != null){
            this.shopTranslatableService.updateShopTranslatable(this.shopTransaltableHu.id, this.shopHuForm.value).subscribe()
          }else{
            this.shopTranslatableService.createShopTranslatable(this.shopHuForm.value).subscribe()
          }     
        }
        if(this.shopGbForm.controls.name.value != '' || this.shopGbForm.controls.description.value != ''){
          this.shopGbForm.patchValue({ shopId: this.shopId});
          if(this.shopTransaltableGb != null){
            this.shopTranslatableService.updateShopTranslatable(this.shopTransaltableGb.id, this.shopGbForm.value).subscribe()
          }else{
            this.shopTranslatableService.createShopTranslatable(this.shopGbForm.value).subscribe()
          } 
        }
      }
    ).add(
      () => {
        if(this.openHours.length > 0){
          this.updateOpenHours(this.shopId, this.openHoursUpdateable);
        }else{
          this.saveOpenhours(this.shopId);
        }
        this.updateTags(this.shopId);
        if(this.uploadedLogoImage != null && this.uploadedLogoImageName !=""){
          this.saveShopLogoImage(this.shopId);
        }
        if(this.uploadedGooglePlayImage != null && this.uploadedGooglePlayImageName !=""){
          this.saveGoogleMedia(this.shopId, this.uploadedGooglePlayImage)
        }
        if(this.uploadedAppleStoreImage != null && this.uploadedAppleStoreImageName !=""){
          this.saveAppleMedia(this.shopId, this.uploadedAppleStoreImage)
        }
        if(this.uploadedShopGalleryImages != null && this.uploadedShopGalleryImages.length > 0){
          this.saveShopGalleryImages(this.shopId);
        }
        if(this.removabelShopImages!= null && this.removabelShopImages.length > 0){
          this.deleteShopImages();
        }
        this.router.navigate(['/shops'])
      }
    );
  }

  private updateOpenHours(shopId: number, openHours: any[]){
    this.openHoursService.updateOpenHoursByShopId(shopId, openHours).subscribe();
  }

  private updateTags(shopId: number){
    for(let t of this.newTags){
      let tag: Tag ={
        title: ''
      }
      if(!this.existingTags.includes(t)){
        tag.title = t;
        this.tagService.createTag(tag).subscribe(
          (data: any) => {
            this.shopTagService.createShopTag(shopId, data.id).subscribe();
          }
        );
      }else{
        this.tagService.getTagByTitle(t).subscribe(
          (data: any) => {
            this.shopTagService.createShopTag(shopId, data.id).subscribe();
          }
        )
      }
    }
    this.deleteShopTags(shopId);
  }

  private deleteShopTags(shopId: number){
    for(let t of this.removableTags){
      this.tagService.getTagByTitle(t).subscribe(
        (data: any) => {
          this.shopTagService.deleteByShopIdAndTagId(shopId, data.id).subscribe();
        }
      )
    } 
  }

  private deleteShopImages(){
    for(let shopImage of this.removabelShopImages){
      this.mediaService.deleteShopImage(shopImage.id).subscribe();
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(value);
      this.newTags.push(value);
    }
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    this.removableTags.push(tag);
    if (index >= 0) 
    {
      this.tags.splice(index, 1);
    }
  }

  onChangeLang($event: string){
    if($event === 'hu'){
      this.hunFormVisible =true;
      this.gbFormVisible =false;
    } else{
      this.hunFormVisible =false;
      this.gbFormVisible =true;
    }
  }

  onLogoImageUpload(event: any) {
    if( event.target.files[0].size <= this.imageSizeLimit){
      this.uploadedLogoImage = event.target.files[0];
      this.uploadedLogoImageName =this.uploadedLogoImage.name;
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
  }

  onGooglePlayImageUpload(event: any){
    if(event.target.files[0].size <= this.imageSizeLimit){
      this.uploadedGooglePlayImage = event.target.files[0];
      this.uploadedGooglePlayImageName =this.uploadedGooglePlayImage.name;
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
    this.checkAppleandGoogle();
  }

  onAppleStoreImageUpload(event: any){
    if(event.target.files[0].size <= this.imageSizeLimit){
      this.uploadedAppleStoreImage= event.target.files[0];
      this.uploadedAppleStoreImageName =this.uploadedAppleStoreImage.name;
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
    this.checkAppleandGoogle();
  }

  onImageUpload(event: any) {
    let image: File;
    image = event.target.files[0]
    if(image.size <= this.imageSizeLimit){
      this.uploadedShopGalleryImages.push(image); 
      this.galleryImageNames.push(image.name) 
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    } 
  }

  onClickRemoveLogoImage(){
    this.uploadedLogoImageName ="";
    this.shopForm.patchValue({ logoId: null});
  }

  onClickRemoveGooglePlayImage(){
    this.uploadedGooglePlayImageName ="";
    this.shopForm.patchValue({ googlePlayLinkImageId: null});
    this.checkAppleandGoogle();
  }

  onClickRemoveAppleStoreImage(){
    this.uploadedAppleStoreImageName ="";
    this.shopForm.patchValue({ appleStoreLinkImageId: null});
    this.checkAppleandGoogle();
  }

  onClickRemoveShopImage(imageName: string){
    if(this.shopImages != null){
      for(let shopImage of this.shopImages){
        if(imageName === shopImage.media.filePath.substring(15)){
          this.removabelShopImages.push(shopImage);
        }
      }
    }
    if(this.galleryImageNames != null && this.galleryImageNames.length >0){
      if(this.galleryImageNames.includes(imageName)){
        const index = this.galleryImageNames.indexOf(imageName);
        this.galleryImageNames.splice(index,1)

      }
      for(let image of this.uploadedShopGalleryImages){
        if(image.name === imageName){
          const index = this.uploadedShopGalleryImages.indexOf(image);
          this.uploadedShopGalleryImages.splice(index,1)
        }
      }
    }
  }

  onGoogleAppleLinkChange(){
    this.checkAppleandGoogle();
   }
 
   private checkAppleandGoogle() {
     if(this.uploadedAppleStoreImageName === "" || this.uploadedGooglePlayImageName ==="" || this.shopForm.controls.googlePlayLink === null 
     || this.shopForm.controls.appleStoreLink.value === null || this.shopForm.controls.googlePlayLink.value === '' || this.shopForm.controls.appleStoreLink.value === '' ){
       this.isGoogleAppleLink = true;
     }else{
       this.isGoogleAppleLink = false;
     }
   }
}
