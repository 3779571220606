
<div mat-dialog-title class="header-container">
    <h2>{{ 'openHours' | translate }}</h2>
</div>
<div mat-dialog-content>
    <form [formGroup]="openHoursForm" >
            <div style="display: inline;">
                <mat-label>{{"MONDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourMonday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteMonday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourMonday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteMonday">
                </mat-form-field><br/>
                <mat-label>{{"TUESDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourTuesday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteTuesday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourTuesday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteTuesday">
                </mat-form-field><br/>
                <mat-label>{{"WEDNESDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourWednesday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteWednesday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourWednesday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteWednesday">
                </mat-form-field><br/>
                <mat-label>{{"THURSDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourThursday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteThursday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourThursday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteThursday">
                </mat-form-field><br/>
                <mat-label>{{"FRIDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourFriday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteFriday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourFriday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteFriday">
                </mat-form-field><br/>
                <mat-label>{{"SATURDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourSaturday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteSaturday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourSaturday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteSaturday">
                </mat-form-field><br/>
                <mat-label>{{"SUNDAY" | translate}}: </mat-label>&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="openingHourSunday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="openingMinuteSunday">
                </mat-form-field>&nbsp;&nbsp;-&nbsp;&nbsp;
                <mat-form-field style="width: 70px;">
                    <mat-label>{{"hour" | translate}}:</mat-label>
                    <input type="number" min="0" max="24" matInput formControlName="closeHourSunday">
                </mat-form-field>:&nbsp;
                <mat-form-field  style="width: 70px;">
                    <mat-label>{{"minute" | translate}}:</mat-label>
                    <input type="number" min="0" max="59" matInput formControlName="closeMinuteSunday">
                </mat-form-field><br/>
            </div> 
    </form><br/>
</div>
<div mat-dialog-actions>
    <button mat-raised-button  (click)="onClickSet()"  [disabled]="openHoursForm.invalid">{{"Beállítás" | translate}}</button>
    <button mat-raised-button  [matDialogClose]="'Mégsem'">{{"Mégsem" | translate}}</button>
</div>
