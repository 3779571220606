<br/><br/><br/>
<div style="text-align: center;" *ngIf="isEditMode">
    <h1 style="font-weight: bold; color:#1c2453;">{{"articleEdit" | translate}}</h1>
</div>
<div style="text-align: center;" *ngIf="!isEditMode">
    <h1 style="font-weight: bold; color:#1c2453;">{{"articleCreate" | translate}}</h1>
</div><br/><br/>

<div style="margin-left: 30px;">
    <form [formGroup]="langForm">
        <mat-form-field class="full-width-field">
            <mat-label>{{"lang" | translate}}:</mat-label>
            <mat-select formControlName="lang" (selectionChange)="onChangeLang($event.value)" required>
                <mat-option value="hu">{{ "hu" | translate}}</mat-option>
                <mat-option value="gb">{{ "gb" | translate}}</mat-option>
            </mat-select>
        </mat-form-field><br/><br/>
    </form>
</div>

<form [formGroup]="articleTranslatableHunForm" *ngIf="hunFormVisible">
    <div style="margin-left: 30px;">
        <mat-form-field class="full-width-field">
            <mat-label>{{"title" | translate}}:</mat-label>
            <input matInput formControlName="title" required>
            <mat-error *ngIf="articleTranslatableHunForm.controls.title.hasError('required')">
                {{ "fill.title.field" | translate}} <strong>{{ "is.required" | translate }}</strong>
            </mat-error>
        </mat-form-field><br/><br/>
        <mat-label>{{"mainText" | translate}}:</mat-label>
        <mat-error *ngIf="articleTranslatableHunForm.controls.mainText.hasError('required')">
            {{ "fill.mainText.field" | translate}} <strong>{{ "is.required" | translate }}</strong>
        </mat-error>
        <angular-editor formControlName="mainText" [config]="config" name="editorOne" required accept=".jpg,.png,.jpeg"></angular-editor>
    </div>
</form>

<form [formGroup]="articleTranslatableGbForm"  *ngIf="gbFormVisible">
    <div style="margin-left: 30px;">
        <mat-form-field class="full-width-field">
            <mat-label>{{"title" | translate}}:</mat-label>
            <input matInput formControlName="title" required>
        </mat-form-field><br/>
        <angular-editor formControlName="mainText" [config]="config" name="editorOne" accept=".jpg,.png,.jpeg"></angular-editor>
    </div>
</form><br/>

<div style="margin-left: 30px;">
    <mat-form-field class="input">
        <mat-chip-list #taglist>
            <mat-chip selected style="background-color: #1c2453;"  *ngFor="let tag of tags"  [selectable]="selectable" [removable]="removable"  (removed)="removeTag(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder='{{"addTags" | translate}}'
            [matChipInputFor]="taglist"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
            (matChipInputTokenEnd)="addTag($event)" />
        </mat-chip-list>
    </mat-form-field><br/><br/>
</div>

<form [formGroup]="articleForm">
    <div style="margin-left: 30px; display: inline;">
        <mat-form-field class="full-width-field">
            <mat-label>{{"author" | translate}}:</mat-label>
            <input matInput formControlName="author" readonly>
        </mat-form-field>&nbsp;
        <mat-form-field class="full-width-field">
            <mat-label>{{"articleType" | translate}}:</mat-label>
            <mat-select formControlName="articleType"  [disabled]="isArticleTypeReadOnly">
                <mat-option value="NEWS">{{"NEWS" | translate}}</mat-option>
                <mat-option value="PARKING">{{"PARKING" | translate}}</mat-option>
                <mat-option value="PUBLIC_TRANSPORTATION">{{"PUBLIC_TRANSPORTATION" | translate}}</mat-option>
                <mat-option value="ETELE">{{"ETELE" | translate}}</mat-option>
                <mat-option value="ELECTRIC_CAR">{{"ELECTRIC_CAR" | translate}}</mat-option>
                <mat-option value="WELL">{{"WELL" | translate}}</mat-option>
                <mat-option value="BUILDING_INFO">{{"BUILDING_INFO" | translate}}</mat-option>
                <mat-option value="P_R">{{"P_R" | translate}}</mat-option>
            </mat-select>
        </mat-form-field><br/>
    </div>
    
    <div style="margin-left: 30px;">
            <div class="form-group">
                <input type="file" (change)="onGooglePlayImageUpload($event)" #googlePlayImageInput style="display: none;" accept=".jpg,.png,.jpeg">
                <button mat-raised-button (click)="googlePlayImageInput.click()" style="display: inline;">{{"browseGooglePlayImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
                <div *ngIf="uploadedGooglePlayImageName ===''"  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
                <div style="display: inline;" *ngIf="uploadedGooglePlayImageName !=''">
                    <mat-chip style="display: inline;">{{ uploadedGooglePlayImageName | translate}} <mat-icon inline=true (click)="onClickRemoveGooglePlayImage()">delete</mat-icon> </mat-chip>
                </div>&nbsp;
                <mat-form-field class="full-width-field">
                    <mat-label>{{"googlePLayLink" | translate}}:</mat-label>
                    <input matInput formControlName="googlePlayLink" (change)="onGoogleAppleLinkChange()">
                </mat-form-field><br/><br/>
            </div>
    </div> 


    <div style="margin-left: 30px;">
            <div class="form-group">
                <input type="file" (change)="onAppleStoreImageUpload($event)" #appleStoreImageInput style="display: none;" accept=".jpg,.png,.jpeg">
                <button mat-raised-button (click)="appleStoreImageInput.click()" style="display: inline;">{{"browseAppleStoreImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
                <div *ngIf="uploadedAppleStoreImageName ===''"  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
                <div style="display: inline;" *ngIf="uploadedAppleStoreImageName !=''">
                    <mat-chip style="display: inline;">{{ uploadedAppleStoreImageName | translate}} <mat-icon inline=true (click)="onClickRemoveAppleStoreImage()">delete</mat-icon> </mat-chip>
                </div>&nbsp;
                <mat-form-field class="full-width-field">
                    <mat-label>{{"appleStoreLink" | translate}}:</mat-label>
                    <input matInput formControlName="appleStoreLink" (change)="onGoogleAppleLinkChange()">
                </mat-form-field><br/><br/>
            </div>
            <br>
    </div> 
</form>

<div style="margin-left: 30px;">
    <form>
        <div class="form-group">
            <input type="file" (change)="onArticleCardImageUpload($event)" #logoInput style="display: none;" accept=".jpg,.png,.jpeg">
            <button mat-raised-button  (click)="logoInput.click()" style="display: inline;">{{"browseCardImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
            <div *ngIf="uploadedCardImageName ===''"  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
            <div style="display: inline;" *ngIf="uploadedCardImageName !=''">
                <mat-chip style="display: inline;">{{ uploadedCardImageName | translate}} <mat-icon inline=true (click)="onClickRemoveArticleCardImage()">delete</mat-icon> </mat-chip>
            </div>
        </div>
        <br>
    </form>
</div><br/>

<div style="margin-left: 30px;">
    <form>
        <div class="form-group">
            <input type="file" (change)="onImageUpload($event)" #imageInput style="display: none;" accept=".jpg,.png,.jpeg">
            <button mat-raised-button (click)="imageInput.click()" style="display: inline;">{{"browseImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
            <div *ngIf='galleryImageNames.length == 0'  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
            <div *ngIf="galleryImageNames.length != 0"  style="display: inline;">
                <mat-chip style="display: inline;" *ngFor="let image of galleryImageNames">{{image}} <mat-icon inline=true (click)="onClickRemoveArticleImage(image)">delete</mat-icon></mat-chip>
            </div>
        </div>
        <br>
    </form>
</div><br/> 


<br/>
<mat-error *ngIf="isGoogleAppleLink">
    {{ "appleGoogleError" | translate}}
</mat-error><br/>
<button mat-raised-button (click)="onClickSave()" [disabled]="articleTranslatableHunForm.invalid || langForm.invalid || isGoogleAppleLink"  style="margin-left: 30px;">{{"common.save" | translate}}</button>
<br/><br/>
  
