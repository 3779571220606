import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(  
    private router: Router,
    private keycloakService: KeycloakService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
   };
  }

  title = 'bpOneFe';

  onClickLogout(){
    this.keycloakService.logout().then();
  }
}
