import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { DialogManagerService } from '../shared/service/dialog-manager.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private dialogManagerService: DialogManagerService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + "/articles",
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    // requiredRoles.every((role) => this.roles.includes(role))
    if (this.roles.filter(role => requiredRoles.includes(role))) {
      return true
    } else {
      this.dialogManagerService.openInfoSweetalertDialog(
        {
          titleKey: "noAuthorization",
          icon: "warning"
        }
      ).then();
      return false;
    }
  }
}
