import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleTagService {

  constructor(
    private xhrService: XhrService
  ) { }

  getArticleTags() {
    return this.xhrService.get(API.articleTags);
  }

  getArticleTagsByArticleId(articleId: number) {
    return this.xhrService.get(API.articleTagsByArticleId + articleId);
  }

  createArticleTag(articleId: number, tagId: number) {
    return this.xhrService.post(API.articleTags + "/" + articleId + "/" + tagId, {}, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteByArticleIdAndTagId(articleId: number, tagId: number){
    return this.xhrService.delete(API.deleteArticleTagByArticleIdAndTagId + articleId +"/" + tagId);
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.articleTags, id);
  }
}
