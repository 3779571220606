import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ShopTranslatableService {

  constructor(
    private xhrService: XhrService
  ) { }

  getShopsTranslatable(lang: string){
    return this.xhrService.get(API.shopsTranslatableByLang + lang);
  }

  getShopTranslatable(id: number){
    return this.xhrService.get(ShopTranslatableService.getTasksEndpointWithPathParam(id));
  }

  getShopTranslatableByShopIdAndLang(shopId: number, lang: string){
    return this.xhrService.get(API.shopsTranslatableByShopIdAndLang + shopId + "/" + lang)
  }

  createShopTranslatable(shopTransaltable: any){
    return this.xhrService.post(API.shopsTranslatable, shopTransaltable, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  updateShopTranslatable(id: number, shopTransaltable: any) {
    return this.xhrService.put(
      ShopTranslatableService.getTasksEndpointWithPathParam(id),
      shopTransaltable,
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.shopsTranslatable, id);
  }
}
