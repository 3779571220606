export class URLUtilsService {
    constructor() {}
  
    static getEndpointWithPathParam(baseEndPoint: string, id: number | string): string {
      return `${baseEndPoint}/${id}`;
    }

    /*static replaceURLParams(url: string, params: object): string {
      let replacedUrl = url;
      Object.keys(params).forEach((key) => {
        replacedUrl = replacedUrl.replace(`:${key}`, params[key]);
      });
      return replacedUrl;
    }*/
  
    static extractRealmFromURL(hostname: string, baseDomain: string): string | null {
      const realmRegex = new RegExp(`(http[s]?://)?(w{3}.)?([a-zA-Z0-9-_]+)(.${baseDomain})`, 'm');
      const regexGroups = realmRegex.exec(hostname);
  
      return regexGroups ? regexGroups[3] : null;
    }
  }