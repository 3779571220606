import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private xhrService: XhrService) { }

  getMedia(id: number) {
    return this.xhrService.get(MediaService.getTasksEndpointWithPathParam(id));
  }

  getShopImages(shopId: number){
    return this.xhrService.get(API.getShopImages + shopId);
  }

  getArticleImages(articleId: number){
    return this.xhrService.get(API.getArticleImages + articleId);
  }

  deleteShopImage(id: number){
    return this.xhrService.delete(API.deleteShopImage + id, {},
    { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteArticleImage(id: number){
    return this.xhrService.delete(API.deleteArticleImage + id, {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.media, id);
  }

}