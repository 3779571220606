import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/service/user.service';
import { UserComponent } from './user/user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public users: any[] = [];
  public dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any>();
  public displayedColumns: string[] = [
    'id',
    'firstName',
    'lastName',
    'username',
    'email',
    'edit',
    'enable/disable',
    'delete'
  ];

  constructor(
    private userService: UserService,
    private _liveAnnouncer: LiveAnnouncer,
    private translateService: TranslateService,
    private matDialog: MatDialog,
  ) { }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(){
    this.userService.getUsers().subscribe(
      data => {
        this.users = data as any[];
        this.dataSource.data = data as any[];
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  onClickAdd(){
    const dialogRef = this.matDialog.open(UserComponent, {
      width: '570px',
      height: '540px',
      data: {isEditMode: false }
    });

    dialogRef.afterClosed().subscribe(
      () => this.getUsers()
    );
  }

  onClickEdit(username: string){
    const dialogRef = this.matDialog.open(UserComponent, {
      width: '570px',
      height: '440px',
      data: { username: username, isEditMode: true }
    });

    dialogRef.afterClosed().subscribe(
        () => this.getUsers()
    );
  }

  onClickUpdateStatus(id: string, status: string){
    if(window.confirm(this.translateService.instant(status==='ACTIVE' ? "common.confirm.enable" : "common.confirm.disable"))){
      this.userService.updateUserStatus(id,status).subscribe(
        () => this.getUsers()
      );
    }
  }

  onClickDelete(username: string){
    if(window.confirm(this.translateService.instant("common.confirm.delete"))){
      this.userService.deleteUser(username).subscribe(
        () => this.getUsers()
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
