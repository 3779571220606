import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";
import { NotificationContent, NotificationWithoutTranslation } from '../interfaces/notification.interfaces';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private matSnackbar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  showSweetAlertSuccessMessage(content: NotificationContent) {
    const Toast = NotificationService.getSwalMixin();

    Toast.fire({
      icon: 'success',
      title: this.translateService.instant(content.key)
    })
  }

  showSweetAlertErrorMessage(content: NotificationContent) {
    const Toast = NotificationService.getSwalMixin();

    Toast.fire({
      icon: 'error',
      title: this.translateService.instant(content.key)
    })
  }

  showSuccessMessage(content: NotificationContent): void {
    this.matSnackbar.open(this.translateService.instant(content.key), content.label, { panelClass: ['success-snackbar'] });
  }

  showErrorMessage(content: NotificationContent): void {
    this.matSnackbar.open(this.translateService.instant(content.key), content.label, { panelClass: ['error-snackbar'] });
  }

  showPreTranslatedErrorMessage(content: NotificationWithoutTranslation): void {
    this.matSnackbar.open(content.message);
  }

  private static getSwalMixin(): typeof Swal {
    return Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    })
  }
}
