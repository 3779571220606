import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ArticleStatus, ArticleType } from 'src/app/global/global.enums';
import { ArticleTranslatableService } from 'src/app/service/article-translatable.service';
import { ArticleService } from 'src/app/service/article.service';

const nestedProperty = (data: any, sortHeaderId: string): string | number => {
  return sortHeaderId.split('.').reduce((accumulator, key) => accumulator && accumulator[key], data) as | string | number
}

const sortingDataAccessor = {
  nestedProperty
}


@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  public articles: any[] = [];
  public dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any>();
  public displayedColumns: string[] = [
    'articleId',
    'title',
    'article.author',
    'article.articleType',
    'article.created',
    'article.published',
    'article.articleStatus',
    'edit',
    'publish',
    'hide',
    'delete'
  ];

  public articleType: any;

  constructor(
    private articleService: ArticleService,
    private articleTranslatableService: ArticleTranslatableService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer
  ) { 
    this.articleType = this.route.snapshot.params.articleType;
    this.getArticles();
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.getArticles();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getArticles();
    });  
  }

  getArticles(){
    if(this.articleType != null){
     this.articleTranslatableService.getArticlesTranslatableByArticleType(this.articleType, this.translateService.currentLang).subscribe(
      data => {
         this.articles = data as any[];
         this.dataSource.data = data as any[];
      });
    }else{
      this.articleType=ArticleType.NEWS
      this.articleTranslatableService.getArticlesTranslatableByArticleType(this.articleType, this.translateService.currentLang).subscribe(
        data =>  {
          this.articles= data as any[]
          this.dataSource.data = data as any[];
        }
      );
    }
    this.dataSource.sortingDataAccessor = sortingDataAccessor.nestedProperty;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onClickAdd(){
    if(this.articleType != null){
      this.router.navigate(['/article/' + this.articleType]);
    }else{
      this.router.navigate(['/article']);
    }
    
  }

  onClickEdit(articleId: number, articleTranslatabelId: number){
    this.router.navigate(['/article/'+ articleId + "/" + articleTranslatabelId]);
  }

  onClickDelete(id: number){
    if(window.confirm(this.translateService.instant("common.confirm.delete"))){
      this.articleService.deleteArticle(id).subscribe(
        () => this.getArticles()
      );
    }
  }

  onClickHide(id: number){
    if(window.confirm(this.translateService.instant("common.confirm.hide"))){
      this.articleService.getArticle(id).subscribe(
        (data: any) => {
          data.articleStatus = ArticleStatus.HIDDEN
          this.articleService.updateArticle(id, data).subscribe(
            () => this.getArticles()
          );
        }
      );
    }
  }

  onClickPublish(id: number){
    if(window.confirm(this.translateService.instant(this.articleType ===ArticleType.NEWS ? "common.confirm.publish" : "common.confirm.limitedArticle"))){
      this.articleService.getArticle(id).subscribe(
        (data: any) => {
          data.articleStatus = ArticleStatus.PUBLISHED;   
          data.published = new Date();
          this.setPublishedArticlesToHidden(id);
          this.articleService.updateArticle(id, data).subscribe(
            () => this.getArticles()
          );
        }
      );
    }
  }

  private setPublishedArticlesToHidden(id:number){
    if(this.articleType != ArticleType.NEWS){
      let publishedArticles = this.articles.filter(article => { return article.article.articleStatus === ArticleStatus.PUBLISHED && article.articleId != id });
      for(let article of publishedArticles){
        article.article.articleStatus = ArticleStatus.HIDDEN;
        this.articleService.updateArticle(article.articleId, article.article).subscribe(
  
        );
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
