<br/>
<div style="text-align: center;" *ngIf="isEditMode">
    <h1 style="font-weight: bold;  color:#1c2453;">{{"userEdit" | translate}}</h1>
</div><br/>
<div style="text-align: center;"  *ngIf="!isEditMode">
    <h1 style="font-weight: bold;  color:#1c2453;">{{"userCreate" | translate}}</h1>
</div><br/>

<form [formGroup]="userForm" novalidate>

    <mat-form-field class="full-width-field"  *ngIf="!isEditMode">
      <mat-label>{{"username" | translate}}:</mat-label>
      <input matInput formControlName="username" required>
      <mat-error *ngIf="userForm.controls.username.hasError('required')">
        {{ "fill.user.name.field" | translate}} <strong>{{ "is.required" | translate }}</strong>
      </mat-error>
    </mat-form-field><br/>
  
    <mat-form-field class="full-width-field">
      <mat-label>{{"firstName" | translate}}:</mat-label>
      <input matInput formControlName="firstName" required>
      <mat-error *ngIf="userForm.controls.firstName.hasError('required')">
        {{ "fill.first.name.field" | translate}} <strong>{{ "is.required" | translate }}</strong>
      </mat-error>
    </mat-form-field><br/>
  
    <mat-form-field class="full-width-field">
      <mat-label>{{"lastName" | translate}}:</mat-label>
      <input matInput formControlName="lastName" required>
      <mat-error *ngIf="userForm.controls.lastName.hasError('required')">
        {{ "fill.last.name.field" | translate}} <strong>{{ "is.required" | translate }}</strong>
      </mat-error>
    </mat-form-field><bR/>
  
    <mat-form-field class="full-width-field">
      <mat-label>{{"email" | translate}}:</mat-label>
      <input matInput formControlName="email">
      <mat-error
        *ngIf="userForm.controls.email.hasError('email')
                      && !userForm.controls.email.hasError('required')"
      >
        {{ "please.fill.valid.email" | translate }}
      </mat-error>
    </mat-form-field><bR/>
  
     <mat-form-field class="full-width-field" *ngIf="!isEditMode">
      <mat-label>{{"password" | translate}}:</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="userForm.controls.password.hasError('required')">
        {{ "fill.password.field" | translate}} <strong>{{ "is.required" | translate }}</strong>
      </mat-error>
    </mat-form-field>
  </form><br/><br/>

  <div mat-dialog-actions>
    <button mat-raised-button  (click)="onClickSave()"  [matDialogClose]="user" [disabled]="userForm.invalid">{{"Mentés" | translate}}</button>
    <button mat-raised-button  [matDialogClose]="'Mégsem'">{{"Mégsem" | translate}}</button>
</div>



