import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
/*import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';*/
/*import { ConfirmationDialogData } from '../components/confirmation-dialog/confirmation-dialog.interface';*/
import Swal, { SweetAlertOptions } from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { InfoDialogInterface } from "../components/confirmation-dialog/info-dialog.interface";
/*import {
  MatDialogInterface,
  MatDialogTemplateRefInterface
} from "../components/confirmation-dialog/mat-dialog.interface";*/

@Injectable({
  providedIn: "root",
})
export class DialogManagerService {
  /*private readonly DEFAULT_CONFIRM_DIALOG_DATA: Partial<ConfirmationDialogData> = {
    titleKey: 'common.confirm_dialog.title',
    cancelButtonKey: 'common.no',
    confirmButtonKey: 'common.yes',
    icon: 'question'
  };*/

  constructor(
    private matDialog: MatDialog,
    private translationService: TranslateService
  ) {
  }

  openInfoSweetalertDialog(infoDialogInterface: InfoDialogInterface) {

    let options: SweetAlertOptions = {
      title: /*this.translationService.instant(infoDialogInterface.titleKey),*/"Title",
      icon: infoDialogInterface.icon
    }

    return Swal.fire(options);
  }

  /*openConfirmationDialog(confirmationDialogData: ConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent> {
    const finalConfirmationDialogData = { ...this.DEFAULT_CONFIRM_DIALOG_DATA, ...confirmationDialogData };
    return this.matDialog.open(ConfirmationDialogComponent, { data: finalConfirmationDialogData });
  }

  openConfirmationSweetalertDialog(confirmationDialogData: ConfirmationDialogData) {
    const finalConfirmationDialogData = { ...this.DEFAULT_CONFIRM_DIALOG_DATA, ...confirmationDialogData };

    let options: SweetAlertOptions = {
      title: this.translationService.instant(finalConfirmationDialogData.titleKey),
      text: this.translationService.instant(finalConfirmationDialogData.contentKey),
      icon: finalConfirmationDialogData.icon,
      showCancelButton: true,
      confirmButtonText: this.translationService.instant(finalConfirmationDialogData.confirmButtonKey),
      cancelButtonText: this.translationService.instant(finalConfirmationDialogData.cancelButtonKey)
    }

    if (confirmationDialogData.inputType) {
      options.input = confirmationDialogData.inputType;
    }

    return Swal.fire(options);
  }

  openMatDialog<T, D>(matDialogData: MatDialogInterface<T, D>): MatDialogRef<T> {
    return this.matDialog.open(matDialogData.component, matDialogData.config);
  }

  openMatDialogTemplateRef<T, D>(matDialogData: MatDialogTemplateRefInterface<T, D>): MatDialogRef<T> {
    return this.matDialog.open(matDialogData.templateRef, matDialogData.config);
  }*/
}
