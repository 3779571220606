import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor( 
    private xhrService: XhrService
  ) { }

  getTags() {
    return this.xhrService.get(API.tags);
  }

  getTag(id: number) {
    return this.xhrService.get(TagService.getTasksEndpointWithPathParam(id));
  }

  getTagByTitle(title: string){
    return this.xhrService.get(API.tagByTitle + title);
  }

  createTag(tag: any) {
    return this.xhrService.post(API.tags, tag, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteTag(id: number) {
    return this.xhrService.delete(
      TagService.getTasksEndpointWithPathParam(id),
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  updateTag(id: number, tag: any) {
    return this.xhrService.put(
      TagService.getTasksEndpointWithPathParam(id),
      tag,
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.tags, id);
  }
}
