import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, NextObserver, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DEFAULT_ERROR_MESSAGE } from '../global/global.constant';
import { ErrorNotificationContent, NotificationConfig, NotificationContent } from '../interfaces/notification.interfaces';
import { DialogManagerService } from '../shared/service/dialog-manager.service';
import { NotificationService } from './notification.service';

const httpOptions = {}

@Injectable({
  providedIn: 'root'
})
export class XhrService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private dialogManagerService: DialogManagerService,
    private router: Router
  ) { }

  get<T>(url: string, options: Record<string, unknown> = {}, notificationConfig?: NotificationConfig): Observable<T> {
    return this.http.get<T>(url, options).pipe(
      tap(this.successHandler<T>(notificationConfig?.successMessage)),
      catchError((error) => this.errorHandler(error, notificationConfig?.errorMessage))
    );
  }

  post<T>(
    url: string,
    body: unknown,
    options: Record<string, unknown> = httpOptions,
    notificationConfig?: NotificationConfig,
    statusCodeErrorMessages?: any
  ): Observable<T> {
    return this.http.post<T>(url, body, { ...options, ...httpOptions }).pipe(
      tap(this.successHandler<T>(notificationConfig?.successMessage)),
      catchError((error) => this.errorHandler(error, notificationConfig?.errorMessage, statusCodeErrorMessages))
    );
  }

  put<T>(
    url: string,
    body: unknown,
    options: Record<string, unknown> = {},
    notificationConfig?: NotificationConfig,
    statusCodeErrorMessages?: any
  ): Observable<T> {
    return this.http.put<T>(url, body, options).pipe(
      tap(this.successHandler<T>(notificationConfig?.successMessage)),
      catchError((error) => this.errorHandler(error, notificationConfig?.errorMessage, statusCodeErrorMessages))
    );
  }

  patch<T>(
    url: string,
    body: unknown,
    options: Record<string, unknown> = {},
    notificationConfig?: NotificationConfig,
    statusCodeErrorMessages?: any
  ): Observable<T> {
    return this.http.patch<T>(url, body, options).pipe(
      tap(this.successHandler<T>(notificationConfig?.successMessage)),
      catchError((error) => this.errorHandler(error, notificationConfig?.errorMessage, statusCodeErrorMessages))
    );
  }

  delete<T>(
    url: string,
    options: Record<string, unknown> = {},
    notificationConfig?: NotificationConfig,
    statusCodeErrorMessages?: any
  ): Observable<T> {
    return this.http.delete<T>(url, options).pipe(
      tap(this.successHandler<T>(notificationConfig?.successMessage)),
      catchError((error) => this.errorHandler(error, notificationConfig?.errorMessage, statusCodeErrorMessages))
    );
  }

  private successHandler<T>(successMessageConfig?: NotificationContent): NextObserver<T> {
    return {
      next: (response) => {
        if (successMessageConfig) {
          this.notificationService.showSweetAlertSuccessMessage(successMessageConfig);
        }
      },
    };
  }

  private errorHandler(error: HttpErrorResponse, errorMessageConfig?: ErrorNotificationContent, statusCodeErrorMessages?: any): Observable<never> {
    if (errorMessageConfig !== false) {
      if (error.status === 403) {
        // nincs jogosultsága a végpontot meghívni
        this.dialogManagerService.openInfoSweetalertDialog(
          {
            titleKey: "noAuthorization",
            icon: "warning"
          }
        ).then(() => {
          this.router.navigate(['/']).then(r => EMPTY);
        });
      } else {
        if ((statusCodeErrorMessages && statusCodeErrorMessages[error.status]) || error.error && error.error.hasOwnProperty('errorMessage')) {
          let titleKey = "";
          if(error.error && error.error.hasOwnProperty('errorMessage')) {
            titleKey = error.error.errorMessage;
          } else {
            titleKey = statusCodeErrorMessages[error.status];
          }
          this.dialogManagerService.openInfoSweetalertDialog(
            {
              titleKey: titleKey,
              icon: "warning"
            }
          ).then();
        } else {
          const errorMessageContent = { ...DEFAULT_ERROR_MESSAGE, ...errorMessageConfig };
          this.notificationService.showSweetAlertErrorMessage(errorMessageContent);
        }
      }

    }

    return throwError(error);
  }

 
}
