import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon'; 
import { MatTabsModule } from '@angular/material/tabs';
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular"; 
import { ConfigInitService } from './init/config-init.service';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar } from "@angular/material/snack-bar";
import { ArticlesComponent } from './components/articles/articles.component';
import { MatTableModule } from "@angular/material/table";
import { I18nModule } from './i18n/i18n.modules';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { XhrService } from './service/xhr.service';
import { NotificationService } from './service/notification.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import  {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import { FormDialogComponent } from './shared/components/form-dialog/form-dialog.component';
import { ArticleComponent } from './components/articles/article/article.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ShopsComponent } from './components/shops/shops.component';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { ShopComponent } from './components/shops/shop/shop.component';
import { UsersComponent } from './components/users/users.component';
import { OpenHoursDialogComponent } from './components/shops/shop/open-hours-dialog/open-hours-dialog.component';
import {MatChipsModule} from '@angular/material/chips'; 
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { PaginatorI18nService } from './global/paginatorI18nService';
import { UserComponent } from './components/users/user/user.component';


@NgModule({
  declarations: [
    AppComponent,
    ArticlesComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    FormDialogComponent,
    ArticleComponent,
    ShopsComponent,
    SelectLanguageComponent,
    ShopComponent,
    UsersComponent,
    OpenHoursDialogComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTabsModule,
    KeycloakAngularModule,
    AngularEditorModule,
    MatTableModule,
    I18nModule,
    OverlayModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatSortModule,
    MatPaginatorModule
  ],
  providers: [
    ConfigInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, ConfigInitService],
    },
    MatSnackBar,
    XhrService,
    NotificationService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }
    },
    { 
      provide: MatDialogRef, useValue: {} 
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorI18nService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
