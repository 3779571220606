import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
 selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.css']
})
export class SelectLanguageComponent {

  selectedLang: string;

  constructor(public translate: TranslateService) {
    this.selectedLang = this.translate.currentLang;
   }


  onClickTranslate($event: MouseEvent, selectedLang: any) {
    $event.preventDefault();
    $event.stopPropagation();

    this.selectedLang = selectedLang;
    this.translate.use(selectedLang);
  }
}