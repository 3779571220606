import { ArrayType } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { ArticleType, Language } from '../global/global.enums';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleTranslatableService {

  constructor(
    private xhrService: XhrService
  ) { }

  getArticlesTranslatable(){
    return this.xhrService.get(API.articlesTranslatable);
  }

  getArticlesTranslatableByLang(lang: string){
    return this.xhrService.get(API.articlesTranslatableByLang + lang);
  }

  getArticlesTranslatableByArticleType(articleType: ArticleType, lang: string){
    return this.xhrService.get(API.articlesTranslatableByArticleTypeAndLang + articleType + "/" + lang);
  }

  getArticleTranslatable(id: number){
    return this.xhrService.get(ArticleTranslatableService.getTasksEndpointWithPathParam(id));
  }

  getPublsihedArticlesByType(articleType: ArticleType, lang: string){
    return this.xhrService.get(API.getPublishedArticlesByType + articleType + "/" + lang)
  }

  getArticleTranslatableByArticleIdAndLang(articleId: number, lang: string){
    return this.xhrService.get(API.articlesTranslatableByArticleIdAndLang + articleId + "/" + lang)
  }

  createArticleTranslatable(articleTransaltable: any){
    return this.xhrService.post(API.articlesTranslatable, articleTransaltable, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  updateArticleTranslatable(id: number, articleTransaltable: any) {
    return this.xhrService.put(
      ArticleTranslatableService.getTasksEndpointWithPathParam(id),
      articleTransaltable,
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.articlesTranslatable, id);
  }

}
