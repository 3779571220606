import { T } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ShopTagService {

  constructor(
    private xhrService: XhrService
  ) { }

  getShopTags() {
    return this.xhrService.get(API.shopTags);
  }

  getShopTagsByShopId(shopId: number) {
    return this.xhrService.get(API.shopTagsByShopId + shopId);
  }

  createShopTag(shopId: number, tagId: number) {
    return this.xhrService.post(API.shopTags + "/" + shopId + "/" + tagId, {}, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteByShopIdAndTagId(shopId: number, tagId: number){
    return this.xhrService.delete(API.deleteShopTagByShopIdAndTagId + shopId + "/" + tagId);
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.shopTags, id);
  }
}
