import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpenHours } from 'src/app/models';

export interface DialogData {
  shopId: number;
  openHoursExisting: any[]
}

@Component({
  selector: 'app-open-hours-dialog',
  templateUrl: './open-hours-dialog.component.html',
  styleUrls: ['./open-hours-dialog.component.css']
})
export class OpenHoursDialogComponent implements OnInit {


  public openHoursForm: FormGroup;
 
  public openHours: any[] =[];

  public openHoursExisting: any[] =[];

  constructor(
    public dialogRef: MatDialogRef<OpenHoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
      this.openHoursForm = new FormGroup({
        openingHourMonday: new FormControl(0),
        openingMinuteMonday: new FormControl(0),
        closeHourMonday: new FormControl(0),
        closeMinuteMonday: new FormControl(0),
        openingHourTuesday: new FormControl(0),
        openingMinuteTuesday: new FormControl(0),
        closeHourTuesday: new FormControl(0),
        closeMinuteTuesday: new FormControl(0),
        openingHourWednesday: new FormControl(0),
        openingMinuteWednesday: new FormControl(0),
        closeHourWednesday: new FormControl(0),
        closeMinuteWednesday: new FormControl(0),
        openingHourThursday: new FormControl(0),
        openingMinuteThursday: new FormControl(0),
        closeHourThursday: new FormControl(0),
        closeMinuteThursday: new FormControl(0),
        openingHourFriday: new FormControl(0),
        openingMinuteFriday: new FormControl(0),
        closeHourFriday: new FormControl(0),
        closeMinuteFriday: new FormControl(0),
        openingHourSaturday: new FormControl(0),
        openingMinuteSaturday: new FormControl(0),
        closeHourSaturday: new FormControl(0),
        closeMinuteSaturday: new FormControl(0),
        openingHourSunday: new FormControl(0),
        openingMinuteSunday: new FormControl(0),
        closeHourSunday: new FormControl(0),
        closeMinuteSunday: new FormControl(0),
      })
      if(this.data.openHoursExisting != null){
        this.openHoursExisting = this.data.openHoursExisting;
      }
   }

  ngOnInit(): void {
    if(this.data.openHoursExisting != null){
      this.initOpenHoursForm();
    }
  }

  onClickSet(){
    this.addOpenHour('MONDAY',this.openHoursForm.controls.openingHourMonday.value, this.openHoursForm.controls.openingMinuteMonday.value,
                    this.openHoursForm.controls.closeHourMonday.value, this.openHoursForm.controls.closeMinuteMonday.value);

    this.addOpenHour('TUESDAY',this.openHoursForm.controls.openingHourTuesday.value, this.openHoursForm.controls.openingMinuteTuesday.value,
                    this.openHoursForm.controls.closeHourTuesday.value, this.openHoursForm.controls.closeMinuteTuesday.value);

    this.addOpenHour('WEDNESDAY',this.openHoursForm.controls.openingHourWednesday.value, this.openHoursForm.controls.openingMinuteWednesday.value,
                    this.openHoursForm.controls.closeHourWednesday.value, this.openHoursForm.controls.closeMinuteWednesday.value);
    
    this.addOpenHour('THURSDAY',this.openHoursForm.controls.openingHourThursday.value, this.openHoursForm.controls.openingMinuteThursday.value,
                    this.openHoursForm.controls.closeHourThursday.value, this.openHoursForm.controls.closeMinuteThursday.value);

    this.addOpenHour('FRIDAY',this.openHoursForm.controls.openingHourFriday.value, this.openHoursForm.controls.openingMinuteFriday.value,
                    this.openHoursForm.controls.closeHourFriday.value, this.openHoursForm.controls.closeMinuteFriday.value);
                  
    this.addOpenHour('SATURDAY',this.openHoursForm.controls.openingHourSaturday.value, this.openHoursForm.controls.openingMinuteSaturday.value,
                    this.openHoursForm.controls.closeHourSaturday.value, this.openHoursForm.controls.closeMinuteSaturday.value);

    this.addOpenHour('SUNDAY',this.openHoursForm.controls.openingHourSunday.value, this.openHoursForm.controls.openingMinuteSunday.value,
                    this.openHoursForm.controls.closeHourSunday.value, this.openHoursForm.controls.closeMinuteSunday.value);
                    
    this.dialogRef.close(this.openHours);              
  }

  private addOpenHour(whatWeekDay: string, openingHour: number, openingMinute: number, closeHour:number, closeMinute:number){
    let  openHour: OpenHours ={
      shopId: 0,
      whatWeekDay: '',
      openingHour: 0,
      openingMinute: 0,
      closeHour: 0,
      closeMinute: 0
    };
  
    if(this.data.shopId != null){
      openHour.shopId = this.data.shopId
    }
    openHour.whatWeekDay = whatWeekDay;
    openHour.openingHour = openingHour;
    openHour.openingMinute = openingMinute;
    openHour.closeHour = closeHour;
    openHour.closeMinute = closeMinute;
    this.openHours.push(openHour);
  }

  private initOpenHoursForm(){
    for(let openHour of this.openHoursExisting){
      switch(openHour.whatWeekDay){
        case 'MONDAY':
          this.openHoursForm.patchValue({ openingHourMonday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteMonday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourMonday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteMonday: openHour.closeMinute })
          break;
        case 'TUESDAY':
          this.openHoursForm.patchValue({ openingHourTuesday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteTuesday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourTuesday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteTuesday: openHour.closeMinute })
          break;
        case 'WEDNESDAY':
          this.openHoursForm.patchValue({ openingHourWednesday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteWednesday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourWednesday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteWednesday: openHour.closeMinute })
          break;
        case 'THURSDAY':
          this.openHoursForm.patchValue({ openingHourThursday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteThursday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourThursday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteThursday: openHour.closeMinute })
          break;  
        case 'FRIDAY':
          this.openHoursForm.patchValue({ openingHourFriday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteFriday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourFriday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteFriday: openHour.closeMinute })
          break; 
        case 'SATURDAY':
          this.openHoursForm.patchValue({ openingHourSaturday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteSaturday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourSaturday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteSaturday: openHour.closeMinute })
          break;
        case 'SUNDAY':
          this.openHoursForm.patchValue({ openingHourSunday: openHour.openingHour })
          this.openHoursForm.patchValue({ openingMinuteSunday: openHour.openingMinute })
          this.openHoursForm.patchValue({ closeHourSunday: openHour.closeHour })
          this.openHoursForm.patchValue({ closeMinuteSunday: openHour.closeMinute })
          break;
      }
    }
  }
}
