
<div class="main-header" >
    <!-- <div>
        <h1 style="float: left; margin-top: 10px; margin-left: 40px; font-weight: bold; color: white;">BPONE</h1>
    </div> -->
    <div class="lang-select">
        <app-select-language></app-select-language>
    </div>
</div>
  
