export enum ArticleType {
    NEWS ="NEWS",
    PARKING ="PARKING",
    PUBLIC_TRANSPORTATION="PUBLIC_TRANSPORTATION",
    ETELE="ETELE",
    ELECTRIC_CAR="ELECTRIC_CAR",
    BUILDING_INFO="BUILDING_INFO",
    P_R="P_R"
}

export enum ShopType {
    SHOP ="SHOP",
    RESTAURANT ="RESTAURANT"
}

export enum ArticleStatus {
    PUBLISHED ="PUBLISHED",
    DRAFT ="DRAFT",
    HIDDEN="HIDDEN"
}

export enum Language {
    gb, 
    hu
}
