export const API = {

// Article
articles: 'server/bpone/articles',
articleTranslatable: 'server/bpone/articles/get_article_translatable/',
createArticleTranslatable: 'server/bpone/articles/create_article_translatable/',

// ArticleTranslatable
articlesTranslatable: 'server/bpone/articles_translatable',
articlesTranslatableByLang: 'server/bpone/articles_translatable/get_articles_by_lang/',
articlesTranslatableByArticleTypeAndLang: 'server/bpone/articles_translatable/get_articles_by_type_and_lang/',
articlesTranslatableByArticleIdAndLang: 'server/bpone/articles_translatable/get_article_translatable_by_article_id_and_lang/',
getPublishedArticlesByType: "/server/bpone/articles_translatable/public/get_published_articles/",

//Shop
shops: 'server/bpone/shops',

//ShopTranslatable
shopsTranslatable: 'server/bpone/shops_translatable',
shopsTranslatableByLang: 'server/bpone/shops_translatable/get_shops_by_lang/',
shopsTranslatableByShopIdAndLang: 'server/bpone/shops_translatable/get_shop_translatable_by_shop_id_and_lang/',

//User
users: 'server/bpone/users',
updateUserStatus: "server/bpone/users/update_status/",

//Media
media: 'server/bpone/media',
uploadImage: 'server/bpone/media/upload_image',
uploadShopImage: 'server/bpone/media/upload_shop_image/',
uploadShopLogoImage: 'server/bpone/media/upload_shop_logo_image/',
uploadArticleCardImage: 'server/bpone/media/upload_article_card_image/',
uploadArticleImage: 'server/bpone/media/upload_article_image/',
uploadGoogleMedia: 'server/bpone/media/upload_google_media/',
uploadAppleMedia: 'server/bpone/media/upload_apple_media/',
getShopImages: 'server/bpone/shop_images/get_shop_images_by_shop_id/',
getArticleImages: 'server/bpone/article_images/get_article_images_by_article_id/',
deleteShopImage :'server/bpone/shop_images/',
deleteArticleImage :'server/bpone/article_images/',

//Openhours
openHours: 'server/bpone/open_hours',
openHoursByShopId: 'server/bpone/open_hours/get_open_hours_by_shop_id/',
updateByShopId: 'server/bpone/open_hours/update_open_hours_by_shop_id/',
openHoursByShopIdAndWhatWeekDay: 'server/bpone/open_hours/get_open_hours_by_shop_id_and_what_week_day/',

//tags
tags: 'server/bpone/tags',
tagByTitle: 'server/bpone/tags/get_tag_by_title/',

//articleTags
articleTags: 'server/bpone/article_tags',
articleTagsByArticleId: 'server/bpone/article_tags/get_article_tags_by_article_id/',
deleteArticleTagByArticleIdAndTagId: 'server/bpone/article_tags/delete_by_article_id_and_tag_id/',

//shopTags
shopTags: 'server/bpone/shop_tags',
shopTagsByShopId: 'server/bpone/shop_tags/get_shop_tags_by_shop_id/',
deleteShopTagByShopIdAndTagId: 'server/bpone/shop_tags/delete_by_shop_id_and_tag_id/',
}