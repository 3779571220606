import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { KeycloakService } from 'keycloak-angular';
import { API } from 'src/app/global/api.endpoints';
import { ArticleTranslatableService } from 'src/app/service/article-translatable.service';
import { ArticleService } from 'src/app/service/article.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { TagService } from 'src/app/service/tag.service';
import { Tag } from 'src/app/models';
import { ArticleTagService } from 'src/app/service/article-tag.service';
import { ArticleStatus, ArticleType } from 'src/app/global/global.enums';
import { MediaService } from 'src/app/service/media.service';
import { DEFAULT_SUCCESS_MESSAGE, DEFAULT_ERROR_MESSAGE } from 'src/app/global/global.constant';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from 'src/app/service/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigInitService } from 'src/app/init/config-init.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  public articleForm: FormGroup;
  public articleTranslatableHunForm : FormGroup;
  public articleTranslatableGbForm : FormGroup;
  public langForm: FormGroup;

  public articleTranslatabe: any;
  public articleTransaltableHu: any;
  public articleTransaltableGb: any;

  public articleId: number;
  public articleType: any;

  public hunFormVisible: boolean = true;
  public gbFormVisible: boolean = false;
  public isEditMode: boolean = false;
  public isArticleTypeReadOnly = false;

  public tags: any[]=[];
  public existingTags: any[]=[];
  public removableTags: any[]=[];
  public newTags: any[]=[];
  public selectable: boolean= true;
  public visible: boolean= true;
  public removable: boolean= true;

  public uploadedCardImage: File;
  public uploadedCardImageName: string ="";

  public uploadedGooglePlayImage: File;
  public uploadedGooglePlayImageName: string ="";

  public uploadedAppleStoreImage: File;
  public uploadedAppleStoreImageName: string ="";

  public isGoogleAppleLink: boolean = false;

  public uploadedArticleGalleryImages: File[] =[];
  public galleryImageNames: string[] = [];
  public articleImages: any[] =[];
  public removabelArticleImages: any[] = [];

  public imageSizeLimit: number;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    width: '70rem',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [ ],
    uploadUrl: API.uploadImage,
    uploadWithCredentials: false

  };

  constructor(
    private articleService: ArticleService,
    private articleTranslatableService: ArticleTranslatableService,
    private keycloakService: KeycloakService,
    private tagService: TagService,
    private articleTagService: ArticleTagService,
    private route: ActivatedRoute,
    private router: Router,
    private mediaService: MediaService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private configService: ConfigInitService
  ) { 
      this.articleId = this.route.snapshot.params.id;
      this.articleType = this.route.snapshot.params.articleType;
      this.articleForm = new FormGroup({
        author: new FormControl(this.keycloakService.getUsername()),
        articleType: new FormControl(),
        articleStatus: new FormControl(ArticleStatus.DRAFT),
        created: new FormControl(),
        cardImageId: new FormControl(''),
        googlePlayLinkImageId: new FormControl(''),
        appleStoreLinkImageId: new FormControl(''),
        googlePlayLink: new FormControl(''),
        appleStoreLink: new FormControl('')
      })
      this.articleTranslatableHunForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        mainText: new FormControl('',[Validators.required]),
        lang: new FormControl('hu'),
        articleId: new FormControl(this.articleId != null ? this.articleId : '')
      })
      this.articleTranslatableGbForm = new FormGroup({
        title: new FormControl(''),
        mainText: new FormControl(''),
        lang: new FormControl('gb'),
        articleId: new FormControl(this.articleId != null ? this.articleId : '')
      })
      this.langForm = new FormGroup({    
        lang: new FormControl('', [Validators.required]),
      })

  }

  ngOnInit(): void {
    this.getIMageSizeLimit();
    this.getExistingTags();
    if(this.articleId != null){
      this.isEditMode = true;
      this.isArticleTypeReadOnly = true;
      this.getArtcileTranslatable(this.articleId, this.route.snapshot.params.articleTranslatableId);
      this.getTags(this.articleId);
      this.getArticleImages(this.articleId);
    }else{
      this.langForm.patchValue({ lang:'hu'})
    }
    if(this.articleType != null){
      this.isArticleTypeReadOnly = true;
      this.articleForm.patchValue({ articleType: this.articleType })
    }
  }

  private getIMageSizeLimit(){
    this.configService.getConfig().subscribe(
      data => {
        this.imageSizeLimit =data['IMAGE_SIZE_LIMIT'];
      }
    )
  }

  getArtcileTranslatable(articleId: number, articleTranslatableId: number){
    this.articleTranslatableService.getArticleTranslatable(articleTranslatableId).subscribe(
      (data: any) => {
        this.articleType = data.article.articleType
        this.setArticleFormGroup(data);
        if(data.lang === 'hu'){
          this.hunFormVisible =true;
          this.gbFormVisible =false;
          this.setHunFormGroup(data);
        }else{
          this.hunFormVisible =false;
          this.gbFormVisible =true;
          this.setGbFormGroup(data);
        }
        if(data.article.cardImageId != null){
          this.getUploadedCardImage(data.article.cardImageId);
        }
        if(data.article.googlePlayLinkImageId != null){
          this.getGooglePlayImage(data.article.googlePlayLinkImageId);
        }
        if(data.article.appleStoreLinkImageId != null){
          this.getAppleStoreImage(data.article.appleStoreLinkImageId);
        }
      }
    )
    this.articleTranslatableService.getArticleTranslatableByArticleIdAndLang(articleId, 'gb').subscribe(
      (data: any) => {
          this.articleTransaltableGb = data;
          this.setGbFormGroup(data);
      }
    );
    this.articleTranslatableService.getArticleTranslatableByArticleIdAndLang(articleId, 'hu').subscribe(
      (data: any) => {
        this.articleTransaltableHu = data;
        this.setHunFormGroup(data)
      }
    );
  }

  private setArticleFormGroup(article: any){
    this.langForm.patchValue({ lang: article.lang })
    this.articleForm.patchValue({ articleStatus: article.article.articleType == ArticleType.NEWS ? ArticleStatus.DRAFT : article.article.articleStatus })
    this.articleForm.patchValue({ articleType: article.article.articleType })
    this.articleForm.patchValue({ created: article.article.created })
    this.articleForm.patchValue({ googlePlayLink: article.article.googlePlayLink})
    this.articleForm.patchValue({ appleStoreLink: article.article.appleStoreLink})
  }

  private setHunFormGroup(article: any){
    this.articleTranslatableHunForm.patchValue({ title: article.title })
    this.articleTranslatableHunForm.patchValue({ mainText: article.mainText })
  }

  private setGbFormGroup(article: any){
    if(article != null){
      this.articleTranslatableGbForm.patchValue({ title: article.title })
      this.articleTranslatableGbForm.patchValue({ mainText: article.mainText })
    }   
  }

  private getUploadedCardImage(id: number){
    this.mediaService.getMedia(id).subscribe(
      (data: any)=> {
          this.uploadedCardImageName = data.filePath.substring(15);
          this.articleForm.patchValue({cardImageId: data.id});  
      }
    )
  }

  private getGooglePlayImage(id: number){
    this.mediaService.getMedia(id).subscribe(
      (data: any)=> {
          this.uploadedGooglePlayImageName = data.filePath.substring(15);
          this.articleForm.patchValue({googlePlayLinkImageId: data.id});  
      }
    )
  }

  private getAppleStoreImage(id: number){
    this.mediaService.getMedia(id).subscribe(
      (data: any)=> {
          this.uploadedAppleStoreImageName = data.filePath.substring(15);
          this.articleForm.patchValue({appleStoreLinkImageId: data.id});  
      }
    )
  }

  private getArticleImages(articleId: number){
    this.mediaService.getArticleImages(articleId).subscribe(
      data => {
        this.articleImages = data as any[];
        for(let articleImage of data as any[]){
          this.galleryImageNames.push(articleImage.media.filePath.substring(15))
        }
      }
    );
        
  }

  getTags(articleId: number){
    this.articleTagService.getArticleTagsByArticleId(articleId).subscribe(
      data => {
        for(let articleTag of data as any[]){
          this.tags.push(articleTag.tag.title);
        }
      }
    )
  }

  getExistingTags(){
    this.tagService.getTags().subscribe(
      data => {
        for(let tag of data as any[]){
          this.existingTags.push(tag.title)
        }
      }
    )
  }

  onClickSave(){
    if(this.isEditMode){
      this.updateArticle();
    }else{
     this.saveArticle();
    }
  }

  private saveArticle(){
      this.articleForm.patchValue({ created: new Date() })
      this.articleService.createArticle(this.articleForm.value).subscribe(
        (data: any) => {
          if(this.articleTranslatableHunForm.controls.title.value != '' || this.articleTranslatableHunForm.controls.mainText.value != ''){
            this.articleTranslatableHunForm.patchValue({ articleId: data.id })
            this.articleTranslatableService.createArticleTranslatable(this.articleTranslatableHunForm.value).subscribe();
          } 
          if(this.articleTranslatableGbForm.controls.title.value != '' || this.articleTranslatableGbForm.controls.mainText.value != ''){
            this.articleTranslatableGbForm.patchValue({ articleId: data.id })
            this.articleTranslatableService.createArticleTranslatable(this.articleTranslatableGbForm.value).subscribe();
          }
          this.saveTags(data.id);
          if(this.uploadedCardImage != null  && this.uploadedCardImageName !=""){
            this.saveArticleCardImage(data.id);
          }
          if(this.uploadedGooglePlayImage != null && this.uploadedGooglePlayImageName !=""){
            this.saveGoogleMedia(data.id, this.uploadedGooglePlayImage)
          }
          if(this.uploadedAppleStoreImage != null && this.uploadedAppleStoreImageName !=""){
            this.saveAppleMedia(data.id, this.uploadedAppleStoreImage)
          }
          if(this.uploadedArticleGalleryImages != null && this.uploadedArticleGalleryImages.length > 0){
            this.saveArticleGalleryImages(data.id);
          }
        }
      ).add( 
        () => {
          this.router.navigate(['/articles/' + this.articleType])
        }
      );
  }

  private saveTags(articleId: number){
    for(let t of this.tags){
      let tag: Tag ={
        title: ''
      }
      if(!this.existingTags.includes(t)){
        tag.title = t;
        this.tagService.createTag(tag).subscribe(
          (data: any) => {
            this.articleTagService.createArticleTag(articleId, data.id).subscribe();
          }
        );
      }else{
        this.tagService.getTagByTitle(t).subscribe(
          (data: any) => {
            this.articleTagService.createArticleTag(articleId, data.id).subscribe();
          }
        )
      }
    }
  }

  private saveArticleCardImage(articleId: number) {
    const imageFormData = new FormData();
    imageFormData.append('file', this.uploadedCardImage, this.uploadedCardImage.name);

    this.http.post(API.uploadArticleCardImage + articleId, imageFormData,{  reportProgress: true,
      observe: 'events'}).subscribe(
        (response:any)=> {
          if (response.status === 200) {
            this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
          }else{
            this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
          }

        }
      );
  }

  private saveGoogleMedia(id: number, media: File) {
    const imageFormData = new FormData();
    imageFormData.append('file', media, media.name);

    this.http.post(API.uploadGoogleMedia + id + "/" + "ARTICLE", imageFormData,{  reportProgress: true,
      observe: 'events'}).subscribe(
        (response:any)=> {
          if (response.status === 200) {
            this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
          }else{
            this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
          }

        }
      );
  }

  private saveAppleMedia(id: number, media: File) {
    const imageFormData = new FormData();
    imageFormData.append('file', media, media.name);

    this.http.post(API.uploadAppleMedia + id + "/" + "ARTICLE", imageFormData,{  reportProgress: true,
      observe: 'events'}).subscribe(
        (response:any)=> {
          if (response.status === 200) {
            this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
          }else{
            this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
          }

        }
      );
  }

  private saveArticleGalleryImages(articleId: number){
    for(let image of this.uploadedArticleGalleryImages){
      const imageFormData = new FormData();
      imageFormData.append('file', image, image.name);
      this.http.post(API.uploadArticleImage + articleId, imageFormData,{  reportProgress: true,
        observe: 'events'}).subscribe(
          (response:any)=> {
            if (response.status === 200) {
              this.notificationService.showSweetAlertSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
            }else{
              this.notificationService.showSweetAlertErrorMessage(DEFAULT_ERROR_MESSAGE);
            }
          }
        )
    }

  }

  private updateArticle(){
    this.articleService.updateArticle(this.route.snapshot.params.id, this.articleForm.value).subscribe(
      () => {
        if(this.articleTranslatableHunForm.controls.title.value != '' || this.articleTranslatableHunForm.controls.mainText.value != ''){ 
          this.articleTranslatableHunForm.patchValue({ articleId: this.articleId});
          if(this.articleTransaltableHu != null){
            this.articleTranslatableService.updateArticleTranslatable(this.articleTransaltableHu.id, this.articleTranslatableHunForm.value).subscribe()
          }else{
            this.articleTranslatableService.createArticleTranslatable(this.articleTranslatableHunForm.value).subscribe();
          }
        }
        if(this.articleTranslatableGbForm.controls.title.value != '' || this.articleTranslatableGbForm.controls.mainText.value != ''){
          this.articleTranslatableGbForm.patchValue({ articleId: this.articleId});
          if(this.articleTransaltableGb != null){
            this.articleTranslatableService.updateArticleTranslatable(this.articleTransaltableGb.id, this.articleTranslatableGbForm.value).subscribe()
          }else{
            this.articleTranslatableService.createArticleTranslatable(this.articleTranslatableGbForm.value).subscribe();
          }
        }
      }
    ).add(  
      () => {
        this.updateTags(this.articleId);
        if(this.uploadedCardImage != null && this.uploadedCardImageName !=""){
          this.saveArticleCardImage(this.articleId);
        }
        if(this.uploadedGooglePlayImage != null && this.uploadedGooglePlayImageName !=""){
          this.saveGoogleMedia(this.articleId, this.uploadedGooglePlayImage)
        }
        if(this.uploadedAppleStoreImage != null && this.uploadedAppleStoreImageName !=""){
          this.saveAppleMedia(this.articleId, this.uploadedAppleStoreImage)
        }
        if(this.uploadedArticleGalleryImages != null && this.uploadedArticleGalleryImages.length > 0){
          this.saveArticleGalleryImages(this.articleId);
        }
        if(this.removabelArticleImages!= null && this.removabelArticleImages.length > 0){
          this.deleteArticleImages();
        }
        this.router.navigate(['/articles/'+ this.articleType])
      }
    );
  }

  private updateTags(articleId: number){
    for(let t of this.newTags){
      let tag: Tag ={
        title: ''
      }
      if(!this.existingTags.includes(t)){
        tag.title = t;
        this.tagService.createTag(tag).subscribe(
          (data: any) => {
            this.articleTagService.createArticleTag(articleId, data.id).subscribe();
          }
        );
      }else{
        this.tagService.getTagByTitle(t).subscribe(
          (data: any) => {
            this.articleTagService.createArticleTag(articleId, data.id).subscribe();
          }
        )
      }
    }
    for(let t of this.removableTags){
      this.tagService.getTagByTitle(t).subscribe(
        (data: any) => {
          this.articleTagService.deleteByArticleIdAndTagId(articleId, data.id).subscribe();
        }
      )
    } 
  }

  private deleteArticleImages(){
    for(let articleImage of this.removabelArticleImages){
      this.mediaService.deleteArticleImage(articleImage.id).subscribe();
    }
  }


  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim() && !this.tags.includes(value)) {
      this.tags.push(value);
      this.newTags.push(value);
    }
    if (input) {
      input.value = '';
    }
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    this.removableTags.push(tag);
    if (index >= 0) 
    {
      this.tags.splice(index, 1);
    }
  }

  onArticleCardImageUpload(event: any){
    if(event.target.files[0].size <= this.imageSizeLimit){
      this.uploadedCardImage = event.target.files[0];
      this.uploadedCardImageName =this.uploadedCardImage.name;
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
   
  }

  onGooglePlayImageUpload(event: any){
    if(event.target.files[0].size <= this.imageSizeLimit){
      this.uploadedGooglePlayImage = event.target.files[0];
      this.uploadedGooglePlayImageName =this.uploadedGooglePlayImage.name;
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
    this.checkAppleandGoogle();
  }

  onAppleStoreImageUpload(event: any){
    if(event.target.files[0].size <= this.imageSizeLimit){
      this.uploadedAppleStoreImage= event.target.files[0];
      this.uploadedAppleStoreImageName =this.uploadedAppleStoreImage.name;
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
    this.checkAppleandGoogle();
  }

  onImageUpload(event: any){
    let image: File;
    image = event.target.files[0]
    if(image.size <= this.imageSizeLimit){
      this.uploadedArticleGalleryImages.push(image); 
      this.galleryImageNames.push(image.name);
    }else{
      window.alert(this.translateService.instant("messages.to_big_iamge_size"));
    }
  }

  onClickRemoveArticleCardImage(){
    this.uploadedCardImageName ="";
    this.articleForm.patchValue({ cardImageId: null});
  }

  onClickRemoveGooglePlayImage(){
    this.uploadedGooglePlayImageName ="";
    this.articleForm.patchValue({ googlePlayLinkImageId: null});
    this.checkAppleandGoogle();
  }

  onClickRemoveAppleStoreImage(){
    this.uploadedAppleStoreImageName ="";
    this.articleForm.patchValue({ appleStoreLinkImageId: null});
    this.checkAppleandGoogle();
  }


  onClickRemoveArticleImage(imageName: string){
    if(this.articleImages != null){
      for(let articleImage of this.articleImages){
        if(imageName === articleImage.media.filePath.substring(15)){
          this.removabelArticleImages.push(articleImage);
        }
      }
    }
    if(this.galleryImageNames != null && this.galleryImageNames.length >0){
      if(this.galleryImageNames.includes(imageName)){
        const index = this.galleryImageNames.indexOf(imageName);
        this.galleryImageNames.splice(index,1)

      }
      for(let image of this.uploadedArticleGalleryImages){
        if(image.name === imageName){
          const index = this.uploadedArticleGalleryImages.indexOf(image);
          this.uploadedArticleGalleryImages.splice(index,1)
        }
      }
    }
  }

  onGoogleAppleLinkChange(){
   this.checkAppleandGoogle();
  }

  private checkAppleandGoogle() {
    if(this.uploadedAppleStoreImageName === "" || this.uploadedGooglePlayImageName ==="" || this.articleForm.controls.googlePlayLink === null 
    || this.articleForm.controls.appleStoreLink.value === null || this.articleForm.controls.googlePlayLink.value === '' || this.articleForm.controls.appleStoreLink.value === '' ){
      this.isGoogleAppleLink = true;
    }else{
      this.isGoogleAppleLink = false;
    }
  }

  onChangeLang($event: string){
    if($event === 'hu'){
      this.hunFormVisible =true;
      this.gbFormVisible =false;
    } else{
      this.hunFormVisible =false;
      this.gbFormVisible =true;
    }

  }

}
