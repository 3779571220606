import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private xhrService: XhrService,
  ) { }

  getUsers() {
    return this.xhrService.get(API.users);
  }

  getUserByUsername(username: string){
    return this.xhrService.get(API.users + "/" + username);
  }

  createUser(user: any){
    return this.xhrService.post(API.users, user, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE })
  }

  updateUser(username: string, user:any){
    return this.xhrService.put(API.users + "/" + username, user, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  updateUserStatus(username: string, newStatus: string) {
    return this.xhrService.post(API.updateUserStatus + (username) + "/" + newStatus, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteUser(username: string){
    return this.xhrService.delete(API.users + "/" + username, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

 /* private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.users, id);
  }*/
}
