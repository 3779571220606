import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(
    private xhrService: XhrService,
  ) { }

  getShops() {
    return this.xhrService.get(API.shops);
  }

  getShop(id: number) {
    return this.xhrService.get(ShopService.getTasksEndpointWithPathParam(id));
  }

  createShop(shop: any) {
    return this.xhrService.post(API.shops, shop, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteShop(id: number) {
    return this.xhrService.delete(
      ShopService.getTasksEndpointWithPathParam(id),
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  updateShop(id: number, shop: any) {
    return this.xhrService.put(
      ShopService.getTasksEndpointWithPathParam(id),
      shop,
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.shops, id);
  }
}
