import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class OpenHoursService {

  constructor(private xhrService: XhrService) { }

  getOpenHoursByShopId(shopId: number){
    return this.xhrService.get(API.openHoursByShopId + shopId);
  }

  getOpenHoursByShopIdAndWhatWeekDay(shopId: number, whatWeekDay: String){
    return this.xhrService.get(API.openHoursByShopIdAndWhatWeekDay + shopId + "/" + whatWeekDay);
  }

  createOpenHours(openHours: any){
    return this.xhrService.post(API.openHours, openHours, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  updateOpenHours(id: number, openHours: any){
    return this.xhrService.put(  OpenHoursService.getTasksEndpointWithPathParam(id), openHours, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  updateOpenHoursByShopId(shopId: number, openHours: any[]){
    return this.xhrService.put(  API.updateByShopId + shopId, openHours, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.articles, id);
  }
}
