import { NotificationContent } from "../interfaces/notification.interfaces";

export const DEFAULT_SUCCESS_MESSAGE: NotificationContent = {
    key: 'messages.success',
  };
  
export const DEFAULT_ERROR_MESSAGE: NotificationContent = {
    key: 'messages.unexpected_error',
  };

export const Open_Hours_Hun: String = "Hétfő:<br/>Kedd:<br/>Szerda:<br/>Csütörtök:<br/>Péntek:<br/>Szombat:<br/>Vasárnap:"

export const Open_Hours_Gb: String = "Monday:<br/>Tuesday:<br/>Wednesday:<br/>Thursday:<br/>Friday:<br/>Saturday:<br/>Sunday:"