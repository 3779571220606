
<mat-sidenav-container>  
    <mat-sidenav mode="side" opened style="background-color:  #1c2453">
        <h1 style="text-align: center;  font-weight: bold; color: white;">BPONE</h1><br/>
        <div >
            <ul  style="margin-top: 5em;">
                <li ><b><a [routerLink]="['/articles/','NEWS']" style="color: white;">{{"news" | translate}}</a></b></li><br/>
                <li>
                    <b><a style="color: white;">{{"pages" | translate}}:</a></b><br/><br/>
                    <ul>
                        <li><b><a [routerLink]="['/articles/','ELECTRIC_CAR']"style="color: white;">{{"ELECTRIC_CAR" | translate}}</a></b></li><br/>
                        <li><b><a [routerLink]="['/articles/','BUILDING_INFO']" style="color: white;">{{"BUILDING_INFO" | translate}}</a></b></li><br/>  
                        <li><b><a [routerLink]="['/articles/','ETELE']" style="color: white;">{{"ETELE" | translate}}</a></b></li> <br/>
                        <li><b><a [routerLink]="['/articles/','PUBLIC_TRANSPORTATION']"style="color: white;">{{"PUBLIC_TRANSPORTATION" | translate}}</a></b></li><br/>
                        <li><b><a [routerLink]="['/articles/','PARKING']"style="color: white;">{{"parking" | translate}}</a></b></li><br/>
                        <li><b><a [routerLink]="['/articles/','P_R']" style="color: white;">{{"P_R" | translate}}</a></b></li><br/>  
                        <li><b><a routerLink="/shops" style="color: white;">{{"shops" | translate}}</a></b> </li><br/>
                        <li><b><a [routerLink]="['/articles/','WELL']" style="color: white;">{{"WELL" | translate}}</a></b></li><br/>  
                    </ul>
                </li>  
                <li><b><a routerLink="/users" style="color: white;">{{"users" | translate}}</a></b> </li><br/>
            </ul>
        </div>   
        <div style="bottom: 30px; position: fixed;">
            <button mat-raised-button (click)="onClickLogout()" style="margin-left: 50px;">
                {{"common.logout" | translate}}
            </button>
        </div>
    </mat-sidenav> 
    <mat-sidenav-content>     
        <div>
            <app-header></app-header>
        </div>
        <div class="wrapper">
            <router-outlet> </router-outlet>
        </div>
       <div class="push"></div>
       <div>
           <app-footer></app-footer>
       </div>
    </mat-sidenav-content>
</mat-sidenav-container>
 








  

