import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ArticleStatus } from 'src/app/global/global.enums';
import { ShopTranslatableService } from 'src/app/service/shop-translatable.service';
import { ShopService } from 'src/app/service/shop.service';

const nestedProperty = (data: any, sortHeaderId: string): string | number => {
  return sortHeaderId.split('.').reduce((accumulator, key) => accumulator && accumulator[key], data) as | string | number
}

const sortingDataAccessor = {
  nestedProperty
}

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.css']
})
export class ShopsComponent implements OnInit {
  
  public shopsTranslatable: any[] = [];
  public dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any>();
  public displayedColumns: string[] = [
    'id',
    'name',
    'location',
    'shop.email',
    'shop.phone',
    'shop.website',
    'shop.shopStatus',
    'shop.shopType',
    'edit',
    'publish',
    'hide',
    'delete'
  ];
  constructor(
    private shopService: ShopService,
    private shopTranslatableService: ShopTranslatableService,
    private translateService: TranslateService,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer
  ) {
    this.getShops();
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.getShops();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getShops(); 
    });

  }
  
  getShops(){
    this.shopTranslatableService.getShopsTranslatable(this.translateService.currentLang).subscribe(
      data => {
        this.shopsTranslatable = data as any[];
        this.dataSource.data = data as any[];
        this.dataSource.sortingDataAccessor = sortingDataAccessor.nestedProperty;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  onClickAdd(){
    this.router.navigate(['/shop']);
  }

  onClickEdit(shopId: number, shopTranslatableId: number){
    this.router.navigate(['/shop/'+ shopId+"/"+shopTranslatableId]);
  }

  onClickDelete(id: number){
    if(window.confirm(this.translateService.instant("common.confirm.delete"))){
      this.shopService.deleteShop(id).subscribe(
        () => this.getShops()
      );
    }
  }

  onClickHide(id: number){
    if(window.confirm(this.translateService.instant("common.confirm.hide"))){
      this.shopService.getShop(id).subscribe(
        (data: any) => {
          data.shopStatus = ArticleStatus.HIDDEN
          this.shopService.updateShop(id, data).subscribe(
            () => this.getShops()
          );
        }
      );
    }
  }

  onClickPublish(id: number){
    if(window.confirm(this.translateService.instant("common.confirm.publish"))){
      this.shopService.getShop(id).subscribe(
        (data: any) => {
          data.shopStatus = ArticleStatus.PUBLISHED
          this.shopService.updateShop(id, data).subscribe(
            () => this.getShops()
          );
        }
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
