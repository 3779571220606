import { I } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { OpenHoursDialogComponent } from '../../shops/shop/open-hours-dialog/open-hours-dialog.component';

export interface DialogData {
  username: string;
  isEditMode: boolean
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  public userForm: FormGroup;
  public isEditMode: boolean = this.data.isEditMode;

  public user: any;

  constructor(
    public dialogRef: MatDialogRef<OpenHoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService,
    private router: Router
  ) {
    this.userForm = new FormGroup({
      lastName: new FormControl(''),
      firstName: new FormControl(''),
      username: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      password: new FormControl('')
    });
   }

  ngOnInit(): void {
    if(this.data.username != null){
      this.getUser(this.data.username);
    }

  }

 private getUser(username: string){
    this.userService.getUserByUsername(username).subscribe(
      (data: any) => {
        this.user = data;
        this.initUserForm(data);
      }
    )
  }

 private initUserForm(user: any){
    this.userForm.patchValue({ firstName: user.firstName })
    this.userForm.patchValue({ lastName: user.lastName })
    this.userForm.patchValue({ username: user.username })
    this.userForm.patchValue({ email: user.email })
  }

  private updateUser(username: string, user:any){
    this.userService.updateUser(username, user).subscribe();
  }

  private createUser(user: any){
    this.userService.createUser(user).subscribe();
  }

  onClickSave(){
    if(this.isEditMode){
      this.updateUser(this.data.username, this.userForm.value);
    }else{
      this.createUser(this.userForm.value);
    }
  }

}
