import { Injectable } from '@angular/core';
import { API } from '../global/api.endpoints';
import { DEFAULT_SUCCESS_MESSAGE } from '../global/global.constant';
import { URLUtilsService } from '../shared/utils/urlutils.service';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(
    private xhrService: XhrService
  ) { }

  getArticles() {
    return this.xhrService.get(API.articles);
  }

  getArticle(id: number) {
    return this.xhrService.get(ArticleService.getTasksEndpointWithPathParam(id));
  }

  createArticle(article: any) {
    return this.xhrService.post(API.articles, article, {}, { successMessage: DEFAULT_SUCCESS_MESSAGE });
  }

  deleteArticle(id: number) {
    return this.xhrService.delete(
      ArticleService.getTasksEndpointWithPathParam(id),
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  updateArticle(id: number, article: any) {
    return this.xhrService.put(
      ArticleService.getTasksEndpointWithPathParam(id),
      article,
      {},
      { successMessage: DEFAULT_SUCCESS_MESSAGE }
    );
  }

  private static getTasksEndpointWithPathParam(id: number): string {
    return URLUtilsService.getEndpointWithPathParam(API.articles, id);
  }
}
