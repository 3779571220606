import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  langFlag: string = 'hu'

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  langSelectOnChange(){
    this.langFlag = this.translateService.currentLang;
  }

}
