
<br/>
<div style="text-align: center;">
  <h1 style="font-weight: bold; color:#1c2453;">{{"shops" | translate}}</h1>
</div>

<mat-form-field appearance="standard">
  <mat-label>{{"common.search" | translate}}</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Search columns" #input>
</mat-form-field>
<div style="float: right; display: inline;">
  <button (click)="onClickAdd()"  mat-raised-button >{{ "newShop" | translate}}</button>
</div><br/>
<div>
  <table mat-table matTableResponsive [dataSource]="dataSource" matSort class="mat-elevation-z16">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"id" | translate}}  </th>
      <td mat-cell *matCellDef="let shop"> {{shop.shop.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"name" | translate}}  </th>
      <td mat-cell *matCellDef="let shop"> {{shop.name}} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"location" | translate}}  </th>
      <td mat-cell *matCellDef="let shop">{{shop.location}}</td>
    </ng-container>
    <ng-container matColumnDef="shop.email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"email" | translate}}  </th>
      <td mat-cell *matCellDef="let shop"> {{shop.shop.email}} </td>
    </ng-container>
    <ng-container matColumnDef="shop.phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"phone" | translate}}  </th>
      <td mat-cell *matCellDef="let shop"> {{shop.shop.phone | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="shop.website">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"website" | translate}}  </th>
      <td mat-cell *matCellDef="let shop"> {{shop.shop.website}}</td>
    </ng-container>
    <ng-container matColumnDef="shop.shopStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"shopStatus" | translate}}  </th>
        <td mat-cell *matCellDef="let shop"> {{shop.shop.shopStatus | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="shop.shopType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"shopType" | translate}}  </th>
      <td mat-cell *matCellDef="let shop"> {{shop.shop.shopType | translate}}</td>
  </ng-container>
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let shop">
        <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.edit' | translate }}"
        matTooltipPosition="above" (click)="onClickEdit(shop.shopId, shop.id)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="publish">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let shop">
          <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.publish' | translate }}"
          matTooltipPosition="above" (click)="onClickPublish(shop.shop.id)" [disabled]="shop.shop.shopStatus === 'PUBLISHED'">
            <mat-icon>publish</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="hide">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let shop">
          <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.hide' | translate }}"
          matTooltipPosition="above" (click)="onClickHide(shop.shop.id)" [disabled]="shop.shop.shopStatus === 'HIDDEN'">
            <mat-icon>visibility_off</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let shop">
          <button mat-icon-button class="edit-btn" matTooltip="{{ 'common.tooltip.delete' | translate }}"
          matTooltipPosition="above" (click)="onClickDelete(shop.shop.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
</div>
 
