import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ArticleComponent } from './components/articles/article/article.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ShopComponent } from './components/shops/shop/shop.component';
import { ShopsComponent } from './components/shops/shops.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppComponent
  },
  {
    path: 'articles',
    canActivate: [AuthGuard],
    component: ArticlesComponent
  },
  {
    path: 'articles/:articleType',
    canActivate: [AuthGuard],
    component: ArticlesComponent
  },
  {
    path: 'article',
    canActivate: [AuthGuard],
    component: ArticleComponent
  },
  {
    path: 'article/:articleType',
    canActivate: [AuthGuard],
    component: ArticleComponent
  },
  {
    path: 'article/:id/:articleTranslatableId',
    canActivate: [AuthGuard],
    component: ArticleComponent
  },
  {
    path: 'shops',
    canActivate: [AuthGuard],
    component: ShopsComponent
  },
  {
    path: 'shop',
    canActivate: [AuthGuard],
    component: ShopComponent
  },
  {
    path: 'shop/:id/:shopTranslatableId',
    canActivate: [AuthGuard],
    component: ShopComponent
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    component: UsersComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
