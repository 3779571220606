<br/>
<div style="text-align: center;" *ngIf="isEditMode">
    <h1 style="font-weight: bold; color:#1c2453;">{{"shopEdit" | translate}}</h1>
</div><br/>
<div style="text-align: center;"  *ngIf="!isEditMode">
    <h1 style="font-weight: bold; color:#1c2453;">{{"shopCreate" | translate}}</h1>
</div><br/>

<div style="margin-left: 30px;">
    <form [formGroup]="langForm">
        <mat-form-field class="full-width-field">
            <mat-label>{{"lang" | translate}}:</mat-label>
            <mat-select formControlName="lang" (selectionChange)="onChangeLang($event.value)" required>
                <mat-option value="hu">{{ "hu" | translate}}</mat-option>
                <mat-option value="gb">{{ "gb" | translate}}</mat-option>
            </mat-select>
        </mat-form-field><br/>
    </form>
</div>

<form [formGroup]="shopHuForm" *ngIf="hunFormVisible">
    <div style="margin-left: 30px;">
        <mat-form-field class="full-width-field">
            <mat-label>{{"name" | translate}}:</mat-label>
            <input matInput formControlName="name" required>
        </mat-form-field>&nbsp;
        <mat-form-field class="full-width-field">
            <mat-label>{{"location" | translate}}:</mat-label>
            <input matInput formControlName="location">
        </mat-form-field><br/>
        <mat-label>{{"description" | translate}}:</mat-label>
        <angular-editor formControlName="description" [config]="config" name="editorOne" accept=".jpg,.png,.jpeg"></angular-editor><br/>
    </div> 
</form>
<form [formGroup]="shopGbForm" *ngIf="gbFormVisible">
    <div style="margin-left: 30px;">
        <mat-form-field class="full-width-field">
            <mat-label>{{"name" | translate}}:</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>&nbsp;
        <mat-form-field class="full-width-field">
            <mat-label>{{"location" | translate}}:</mat-label>
            <input matInput formControlName="location">
        </mat-form-field><br/>
        <mat-label>{{"description" | translate}}:</mat-label>
        <angular-editor formControlName="description" [config]="config" name="editorOne" accept=".jpg,.png,.jpeg"></angular-editor><br/>
    </div> 
</form>

<div style="margin-left: 30px;">
    <mat-form-field class="input">
        <mat-chip-list #taglist>
            <mat-chip selected style="background-color: #1c2453;"  *ngFor="let tag of tags"  [selectable]="selectable" [removable]="removable"  (removed)="removeTag(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder='{{"addTags" | translate}}'
            [matChipInputFor]="taglist"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
            (matChipInputTokenEnd)="addTag($event)" />
        </mat-chip-list>
    </mat-form-field><br/><br/>
    <button mat-raised-button  (click)="onClickSetOpenHours()" *ngIf="!isEditMode">{{"setOpenHours" | translate}}</button>
    <button mat-raised-button (click)="onClickSetOpenHours()" *ngIf="isEditMode">{{"editOpenHours" | translate}}</button><br/><br/>
    <div style="width: 200px;white-space: pre-line"><p>{{openHoursView}}</p></div><br/>
</div>

<form [formGroup]="shopForm">
    <div style="margin-left: 30px;"> 
        <div style="display: inline;">
            <mat-form-field class="full-width-field">
                <mat-label>{{"email" | translate}}:</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>&nbsp;
            <mat-error *ngIf="shopForm.controls.email.hasError('email')">
                {{ "please.fill.valid.email" | translate }}
            </mat-error>
            <mat-form-field class="full-width-field">
                <mat-label>{{"phone" | translate}}:</mat-label>
                <input matInput formControlName="phone">
            </mat-form-field>&nbsp;
            <mat-form-field class="full-width-field">
                <mat-label>{{"website" | translate}}:</mat-label>
                <input matInput formControlName="website">
            </mat-form-field>&nbsp;
        </div>
    </div>

    <div style="margin-left: 30px;">
        <div class="form-group">
            <input type="file" (change)="onGooglePlayImageUpload($event)" #googlePlayImageInput style="display: none;" accept=".jpg,.png,.jpeg">
            <button mat-raised-button (click)="googlePlayImageInput.click()" style="display: inline;">{{"browseGooglePlayImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
            <div *ngIf="uploadedGooglePlayImageName ===''"  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
            <div style="display: inline;" *ngIf="uploadedGooglePlayImageName !=''">
                <mat-chip style="display: inline;">{{ uploadedGooglePlayImageName | translate}} <mat-icon inline=true (click)="onClickRemoveGooglePlayImage()">delete</mat-icon> </mat-chip>
            </div>&nbsp;
            <mat-form-field class="full-width-field">
                <mat-label>{{"googlePLayLink" | translate}}:</mat-label>
                <input matInput formControlName="googlePlayLink" (change)="onGoogleAppleLinkChange()">
            </mat-form-field><br/><br/>
        </div>
    </div> 


    <div style="margin-left: 30px;">
            <div class="form-group">
                <input type="file" (change)="onAppleStoreImageUpload($event)" #appleStoreImageInput style="display: none;" accept=".jpg,.png,.jpeg">
                <button mat-raised-button (click)="appleStoreImageInput.click()" style="display: inline;">{{"browseAppleStoreImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
                <div *ngIf="uploadedAppleStoreImageName ===''"  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
                <div style="display: inline;" *ngIf="uploadedAppleStoreImageName !=''">
                    <mat-chip style="display: inline;">{{ uploadedAppleStoreImageName | translate}} <mat-icon inline=true (click)="onClickRemoveAppleStoreImage()">delete</mat-icon> </mat-chip>
                </div>&nbsp;
                <mat-form-field class="full-width-field">
                    <mat-label>{{"appleStoreLink" | translate}}:</mat-label>
                    <input matInput formControlName="appleStoreLink" (change)="onGoogleAppleLinkChange()">
                </mat-form-field><br/><br/>
            </div>
            <br>
    </div> 
</form><br/>

<div style="margin-left: 30px;">
    <form>
        <div class="form-group">
            <input type="file" (change)="onLogoImageUpload($event)" #logoInput style="display: none;" accept=".jpg,.png,.jpeg" >
            <button mat-raised-button  (click)="logoInput.click()" style="display: inline;">{{"browseLogo" | translate}}</button>&nbsp;&nbsp;&nbsp;
            <div *ngIf="uploadedLogoImageName ===''"  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
            <div style="display: inline;" *ngIf="uploadedLogoImageName !=''">
                <mat-chip style="display: inline;">{{ uploadedLogoImageName | translate}} <mat-icon inline=true (click)="onClickRemoveLogoImage()">delete</mat-icon> </mat-chip>
            </div>
        </div>
        <br>
    </form>
</div><br/> 

<div style="margin-left: 30px;">
    <form>
        <div class="form-group">
            <input type="file" (change)="onImageUpload($event)" #imageInput style="display: none;" accept=".jpg,.png,.jpeg">
            <button mat-raised-button (click)="imageInput.click()" style="display: inline;">{{"browseImage" | translate}}</button>&nbsp;&nbsp;&nbsp;
            <div *ngIf='galleryImageNames.length == 0'  style="display: inline;"><mat-chip style="display: inline;" >{{ "noFileSelected" | translate}} </mat-chip></div>
            <div *ngIf="galleryImageNames.length != 0"  style="display: inline;">
                <mat-chip style="display: inline;" *ngFor="let image of galleryImageNames">{{image}} <mat-icon inline=true (click)="onClickRemoveShopImage(image)">delete</mat-icon></mat-chip>
            </div>
        </div>
        <br>
    </form>
</div><br/> 

<mat-error *ngIf="isGoogleAppleLink">
    {{ "appleGoogleError" | translate}}
</mat-error><br/>
<button mat-raised-button (click)="onClickSave()"[disabled]="shopForm.invalid || shopHuForm.invalid || langForm.invalid" style="margin-left: 30px;">{{"common.save" | translate}}</button>
<br/><br/>
  
